import React from "react";
import { Card, Dialog, H3, Tag } from "@blueprintjs/core";
import { Flex, Box } from "reflexbox";
import Button from "./Button";
import _ from "lodash";
import DetectRTC from "detectrtc";
import DailyIframe from "@daily-co/daily-js";
import Help from "./Help";

import Upload from "./Upload";
import cogoToast from "cogo-toast";
import axauth from "../utils/authcall";
import Viewer, { SpecialZoomLevel } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

class AppointmentCard extends React.Component {
  state = {
    eyeUploadOpen: false,
    instructionsOpen: false,
    eyeSequence: [
      {
        fileName: "/illustrations/gazes/rightEyeStraight.jpg",
        eye: "right",
        gaze: "straight",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/rightEyeLeft.jpg",
        eye: "right",
        gaze: "left",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/rightEyeRight.jpg",
        eye: "right",
        gaze: "right",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/rightEyeUp.jpg",
        eye: "right",
        gaze: "up",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/rightEyeDown.jpg",
        eye: "right",
        gaze: "down",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/leftEyeStraight.jpg",
        eye: "left",
        gaze: "straight",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/leftEyeLeft.jpg",
        eye: "left",
        gaze: "left",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/leftEyeRight.jpg",
        eye: "left",
        gaze: "right",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/leftEyeUp.jpg",
        eye: "left",
        gaze: "up",
        psUrl: null,
      },
      {
        fileName: "/illustrations/gazes/leftEyeDown.jpg",
        eye: "left",
        gaze: "down",
        psUrl: null,
      },
    ],
    patientFile: null,
    showFile: false,
    current: 0,
    currentInstruction: 0,
    canUpload: false,
    video: false,
    refresh: 0,
  };
  componentDidMount() {
    this.getDetails();
    this.checkIfItsTime();
  }

  checkIfItsTime = () => {
    var currentTime = new Date();
    var appointTime = new Date(this.props.appointment.time);
    var timeDiff = (appointTime - currentTime) / (1000 * 60);
    if (timeDiff < 0) {
      return null;
    } else if (timeDiff < 60) {
      // within one hour of appointment
      setInterval(
        () => this.setState({ refresh: this.state.refresh + 1 }),
        60000
      );
    }
  };
  getDetails = async () => {
    try {
      var res = await axauth("/v1/user/getAppointmentDetails", {
        _id: this.props.appointment._id,
      });

      var eyeSeq = this.state.eyeSequence;
      _.map(res.data.patientImages, (each) => {
        var whichFile = each.keyName.split("_");

        var idx = _.findIndex(eyeSeq, {
          eye: whichFile[0],
          gaze: whichFile[1],
        });
        eyeSeq[idx].psUrl = each.psUrl;
      });
      this.setState({
        eyeSequence: eyeSeq,
      });
      if (res.data.patientDocs) {
        this.setState({
          patientFile: res.data["patientDocs"][0].psUrl,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  finaliseFiles = async () => {
    try {
      cogoToast.loading("Processing Files...");
      var res = await axauth(
        "/v1/user/uploadPtDocumentsFinalize/" + this.props.appointment._id,
        {}
      );

      this.setState({ patientFile: res.data["mergedDocPsURL"] });
      cogoToast.success("Successfully uploaded files.");
      this.getDetails();
    } catch (error) {
      cogoToast.error(error.message);
    }
  };
  rtcDetails = null;
  videoEvents = [
    "joining-meeting",
    "joined-meeting",
    "left-meeting",
    "error",
    "camera-error",
    "started-camera",
    "participant-joined",
    "participant-updated",
    "participant-left",
    "network-connection",
    "network-quality-change",
    "app-message",
    "fullscreen",
    "exited-fullscreen",
  ];
  canStart = true;
  checkBrowserCompatibility = async () => {
    DetectRTC.load(function() {
      console.log(DetectRTC);
    });
    this.rtcDetails = DetectRTC;
    console.log(DetectRTC.isWebsiteHasWebcamPermissions);
    if (DetectRTC.osName === "Android") {
      if (DetectRTC.browser.name !== "Chrome") {
        cogoToast.error(
          "We only support the browser Chrome on Android. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName === "iOS") {
      if (DetectRTC.browser.name !== "Safari") {
        cogoToast.error(
          "We only support the browser Safari on iOS. Please login again from Safari. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName.includes("Windows")) {
      if (DetectRTC.browser.name !== "Chrome") {
        cogoToast.error(
          "We only support the browser Chrome on Windows. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName === "Mac OS X") {
      if (
        DetectRTC.browser.name !== "Safari" &&
        DetectRTC.browser.name !== "Chrome"
      ) {
        cogoToast.error(
          "We only support the browsers Safari and Chrome on Mac OS X. Please login again from Safari or Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (
      !DetectRTC.isWebsiteHasMicrophonePermissions ||
      !DetectRTC.isWebsiteHasWebcamPermissions
    ) {
      const havePermission = await this.getPermission();
      console.log("HP", havePermission);
      if (havePermission) {
        return true;
      } else {
        cogoToast.error(
          "You have either denied permission to the camera or the microphone. Please check your browser settings and try again."
        );
        return false;
      }
    }

    if (
      DetectRTC.hasMicrophone &&
      DetectRTC.hasSpeakers &&
      DetectRTC.hasWebcam
    ) {
      return true;
    } else {
      cogoToast.error(
        "We could not detect your microphone, speaker or webcam."
      );
    }
  };
  getPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  getToken = async (id) => {
    cogoToast.loading("Checking Appointment Details...");
    const havePermission = await this.checkBrowserCompatibility();
    if (!havePermission) {
      try {
        await axauth("/v1/user/appointmentAnalyticsEx", {
          label: "NO_PERMISSION",
          appointmentId: id,
          ts: new Date(),
          data: this.rtcDetails,
        });
      } catch (err) {
        console.log(err);
      }
      return null;
    }
    try {
      const res = await axauth("/v1/user/appointmentGetMeetingTokenDCO", {
        appointmentId: id,
      });
      //console.log(res.data);
      cogoToast.success("Connecting you to the meeting now.");
      try {
        await axauth("/v1/user/appointmentAnalyticsEx", {
          label: "RTC",
          appointmentId: id,
          ts: new Date(),
          data: this.rtcDetails,
        });
        this.setState({
          token: res.data.token,
          url: res.data.url,
          video: true,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      cogoToast.error("Failed To Fetch Appointment Token");
      console.log(error);
    }
  };
  renderVideo = () => {
    var isMobileDevice = this.rtcDetails
      ? this.rtcDetails.isMobileDevice
      : null;
    var height = "50vh";
    var width = "50vw";
    var right = "1em";
    var bottom = "1em";
    if (isMobileDevice) {
      height = "100vh";
      width = "100vw";
      right = "0em";
      bottom = "0em";
    }
    if (this.state.video && this.canStart) {
      let callFrame = DailyIframe.createFrame({
        showLeaveButton: "true",
        showFullscreenButton: "true",
        url: this.state.url,
        token: this.state.token,
        iframeStyle: {
          position: "fixed",
          border: "1px solid black",
          width: width,
          height: height,
          right: right,
          bottom: bottom,
        },
      });
      this.canStart = false;
      callFrame.join().then((e) => console.log(e));
      this.videoEvents.forEach((event) => {
        callFrame.on(event, (e) => {
          if (event === "left-meeting") {
            this.setState({
              video: false,
            });
            this.canStart = true;
            this.videoEvents.forEach((event) => {
              callFrame.off(event, (e) => console.log(e));
            });
            callFrame.leave();
            callFrame.destroy();
          }
          if (event === "error") {
            cogoToast.error(e.errorMsg);
          }
          this.callAnalytics(event, e);
        });
      });
    } else return null;
  };
  callAnalytics = async (label, data) => {
    console.log("Logging Data", label, data);
    try {
      await axauth("/v1/user/appointmentAnalyticsEx", {
        label: _.toUpper(_.snakeCase(label)),
        appointmentId: this.props.arg._id,
        ts: new Date(),
        data: data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  renderStartAppointment = () => {
    const currentTime = new Date();
    const startConsultTime = new Date(this.props.appointment.time);

    const startConsultBool = (startConsultTime - currentTime) / (1000 * 60);
    const isDone = this.props.appointment.status === "done" ? true : false;
    const consultTimeInRange =
      startConsultBool < 5 && startConsultBool > -30 ? true : false;
    const consultTimeInDeepPast = startConsultBool < -30 ? true : false;
    const consultTimeInDeepFuture = startConsultBool > 5 ? true : false;
    const consultButtonActive = !isDone && consultTimeInRange;
    const notDoneYetInPast = !isDone && consultTimeInDeepPast;
    const notDoneAndInFuture = !isDone && consultTimeInDeepFuture;

    if (consultButtonActive) {
      return (
        <Button
          text="Start Consultation"
          colour="green"
          square
          image="stetho"
          onClick={() => {
            this.getToken(this.props.appointment._id);
          }}
        />
      );
    }
    if (notDoneYetInPast) {
      return (
        <Button
          text="Start Consultation"
          colour="grey"
          square
          image="stetho"
          onClick={() => {
            cogoToast.error(
              "It seems like the start time for this consultation is over. If you think this is an error, please contact support."
            );
          }}
        />
      );
    }
    if (notDoneAndInFuture) {
      return (
        <Button
          text="Start Consultation"
          colour="grey"
          square
          image="stetho"
          onClick={() => {
            cogoToast.loading(
              "You will be able to start the consultation five minutes before the scheduled time."
            );
          }}
        />
      );
    }
    if (isDone) {
      return (
        <Tag large multiline intent="success" minimal>
          Consultation Completed
        </Tag>
      );
    }
  };
  nextSequence = (arg) => {
    if (arg === "next") {
      if (this.state.current === this.state.eyeSequence.length - 1) {
        cogoToast.info("Thanks!");
        this.setState({ eyeUploadOpen: false, current: 0 });
      } else {
        this.setState({ current: this.state.current + 1 });
      }
      return;
    } else if (arg === "previous") {
      if (!this.state.current) {
        cogoToast.error(
          "You can't go back further, this is the first section!"
        );
        return;
      }
      this.setState({ current: this.state.current - 1 });
      return;
    }
  };
  nextInstruction = (arg) => {
    if (arg === "next") {
      if (
        this.state.currentInstruction ===
        this.state.instructions.length - 1
      ) {
        cogoToast.info(
          "Thanks for viewing the instructions! You can see them anytime."
        );
        this.setState({ instructionsOpen: false, currentInstruction: 0 });
      } else {
        this.setState({
          currentInstruction: this.state.currentInstruction + 1,
        });
      }
      return;
    } else if (arg === "previous") {
      if (!this.state.currentInstruction) {
        cogoToast.error(
          "You can't go back further, this is the first section!"
        );
        return;
      }
      this.setState({ currentInstruction: this.state.currentInstruction - 1 });
      return;
    }
  };
  renderInstructionsBox = () => {
    return (
      <Dialog
        isOpen={this.state.instructionsOpen}
        canEscapeKeyClose
        canOutsideClickClose
        isCloseButtonShown
        onClose={() => this.setState({ instructionsOpen: false })}
        title="Instructions"
        icon="eye-on"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Box p={2}>
            <Help />
          </Box>
        </Flex>
      </Dialog>
    );
  };
  renderEyeUploadDialog = () => {
    return (
      <Dialog
        isOpen={this.state.eyeUploadOpen}
        canEscapeKeyClose
        canOutsideClickClose
        isCloseButtonShown
        onClose={() => this.setState({ eyeUploadOpen: false })}
        title="Upload Eye Photographs"
        icon="eye-on"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Box p={2}>
            <H3>
              Upload Images For:&nbsp;
              {_.capitalize(this.state.eyeSequence[this.state.current].eye)} Eye
            </H3>
          </Box>
          <Box p={2}>
            Please click the upload button below to click an image of your&nbsp;
            {this.state.eyeSequence[this.state.current].eye}
            &nbsp;eye looking {this.state.eyeSequence[this.state.current].gaze}.
          </Box>
          <Box p={2}>
            <img
              src={this.state.eyeSequence[this.state.current].fileName}
              width="300px"
              alt="Eye Photograph"
            />
          </Box>
          <Box p={2}>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <Upload
                  what="eye"
                  eye={this.state.eyeSequence[this.state.current].eye}
                  gaze={this.state.eyeSequence[this.state.current].gaze}
                  _id={this.props.appointment._id}
                  key={`${this.props.appointment._id}_${this.props.eye}_${this.props.gaze}`}
                  psUrl={this.state.eyeSequence[this.state.current].psUrl}
                  filesUpdated={() => {
                    this.getDetails();
                  }}
                />
              </Box>
            </Flex>
          </Box>
          <Box p={2}>
            <Button
              text="Previous"
              colour="blue"
              onClick={() => this.nextSequence("previous")}
            />
            <Button
              text="Next"
              colour="green"
              onClick={() => this.nextSequence("next")}
            />
          </Box>
          <Box>
            <Button
              text="Close"
              colour="red"
              onClick={() => this.setState({ eyeUploadOpen: false })}
            />
          </Box>
        </Flex>
      </Dialog>
    );
  };
  renderComplaints = () => {
    return _.map(this.props.appointment.hpi, (c) => {
      if (c["additionalInformation"]) {
        return (
          <>
            <Tag intent="primary">Additional Context</Tag>{" "}
            <div style={{ margin: "auto", maxWidth: "300px" }}>
              {c["additionalInformation"]}
            </div>
            <br />
          </>
        );
      } else {
        return (
          <div style={{ padding: "5px" }}>
            <Tag intent="primary">{c["medical"]}</Tag> in{" "}
            <Tag intent="success">{c["site"]}</Tag> since{" "}
            <Tag intent="warning" minimal>
              {c["duration"]}
            </Tag>{" "}
            onset{" "}
            <Tag intent="warning" minimal>
              {c["onset"]}
            </Tag>
            ; and progression{" "}
            <Tag intent="primary" minimal>
              {c["progression"]}
            </Tag>
            <br />
            Diurnal Variation:{" "}
            <Tag intent="success" minimal>
              {_.join(c["diurnalVariation"], ", ")}
            </Tag>{" "}
            <br />
            Aggravating Factors:{" "}
            <Tag intent="warning" minimal>
              {_.join(c["aggravatingFactors"], ", ")}
            </Tag>{" "}
            <br />
            Relieving Factors:{" "}
            <Tag intent="success" minimal>
              {_.join(c["relievingFactors"], ", ")}
            </Tag>{" "}
            <br />
          </div>
        );
      }
    });
  };
  renderPDF = () => {
    return (
      <div style={{ height: "750px" }}>
        <Viewer
          fileUrl={this.state.patientFile}
          defaultScale={SpecialZoomLevel.PageFit}
        />
      </div>
    );
  };
  render() {
    return (
      <div>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Card elevation={2}>
            <div className="history-question">
              Your Appointment with {this.props.appointment.doctorName}
            </div>
            <Tag minimal intent="primary">
              Scheduled at &nbsp;
              {new Date(this.props.appointment.time).toLocaleString("en-IN", {
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                year: "2-digit",
              })}
            </Tag>
            <div>
              {" "}
              <Button
                text="How To Check Camera and Microphone"
                colour="pink"
                square
                image="help"
                onClick={() => this.setState({ instructionsOpen: true })}
              />
              {this.renderStartAppointment()}
            </div>
            <div style={{ padding: "10px" }}>
              {" "}
              <Tag large intent="danger">
                History of Present Illness
              </Tag>
              <div className="login-text"></div>
              <div>{this.renderComplaints()}</div>
            </div>
            <Tag large intent="warning">
              Upload Old Prescriptions
            </Tag>{" "}
            <br />
            {this.state.patientFile ? (
              <Button
                text={
                  this.state.showFile
                    ? "Hide Uploaded File"
                    : "View Old Uploaded Prescriptions"
                }
                onClick={() =>
                  this.setState({ showFile: !this.state.showFile })
                }
                colour="green"
              />
            ) : null}
            {this.state.showFile ? this.renderPDF() : null}
            <Upload
              what="files"
              _id={this.props.appointment._id}
              key={this.props.appointment._id}
              filesUpdated={() => {
                this.finaliseFiles();
              }}
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <Upload />
              </Box>
            </Flex>
            <Tag large intent="warning">
              Upload Photos Of Your Eyes
            </Tag>{" "}
            <br />
            <br />
            <Button
              text="Start Upload"
              colour="blue"
              onClick={() => this.setState({ eyeUploadOpen: true })}
            />
            <br />
            <br />
            If you need support, please quote this ID:{" "}
            {this.props.appointment._id}
          </Card>
        </Flex>
        {this.renderEyeUploadDialog()}
        {this.renderVideo()}
        {this.renderInstructionsBox()}
      </div>
    );
  }
}

export default AppointmentCard;
