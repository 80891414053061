import React from "react";

import { BrowserRouter, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Worker } from "@phuocng/react-pdf-viewer";

import "./App.css";

import NavBar from "./components/micro/NavBar";
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import Profile from "./components/pages/Profile";
import NewProfile from "./components/pages/NewProfile";
import AppointmentList from "./components/pages/AppointmentList";
import PreSymptomQuestions from "./components/pages/PreSymptomQuestions";
import SymptomList from "./components/pages/SymptomList";
import SymptomHOC from "./components/pages/SymptomHOC";
import AppointmentType from "./components/pages/AppointmentType";

class App extends React.Component {
  state = {
    auth: localStorage.getItem("dtoken"),
  };
  render() {
    if (!this.props.appointmentInfoStore.token) {
      return (
        <div className="App">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <BrowserRouter>
              <Route path="/" component={NavBar} />
              <Route path="/" component={Login} />
            </BrowserRouter>
          </Worker>
        </div>
      );
    }
    return (
      <div className="App">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <BrowserRouter>
            <Route path="/" component={NavBar} />
            <Route path="/" exact component={Login} />
            <Route path="/home" exact component={Home} />
            <Route path="/appointments" exact component={AppointmentList} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/profile/new" exact component={NewProfile} />

            <Route
              path="/appointments/new"
              exact
              component={PreSymptomQuestions}
            />
            <Route path="/appointments/new/list" component={SymptomList} />
            <Route path="/appointments/new/hoc" component={SymptomHOC} />
            <Route path="/appointments/new/book" component={AppointmentType} />
          </BrowserRouter>
        </Worker>
      </div>
    );
  }
}

export default inject("appointmentInfoStore")(observer(App));
