import React from "react";
import { Flex, Box } from "reflexbox";
import { Tag } from "@blueprintjs/core";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import _ from "lodash";
import cogoToast from "cogo-toast";

import axauth from "../utils/authcall";
import Button from "./Button";

class DocProfile extends React.Component {
  state = {
    expanded: false,
    selectedDay: null,
    fees: null,
    bio: "Loading...",
    nextAvailable: null,
    image: "/illustrations/dummy-profile-photo.jpg",
    lessThanFour: [],
    lessThanSix: [],
    unavailableDates: [],
    slots: [],
    selectedSlot: null,
    busy: true,
  };
  componentDidMount() {
    this.getSlotsForMonth(new Date().getMonth(), new Date().getFullYear()).then(
      (v) => v
    );
  }
  getSlotsForMonth = async (month, year) => {
    try {
      var res = await axauth("/v1/user/getDoctorSlots", {
        _id: this.props._id,
        month: month,
        year: year,
        clinic: "Virtual Clinic",
      });

      var lessThanFour = [];
      var lessThanSix = [];
      var unavailableDates = [];
      var slots = [];
      _.forEach(res.data, (day) => {
        var obj = { date: new Date(day.date), slots: _.flatten(day.slots) };
        slots.push(obj);
        if (new Date(day.date).getTime() < new Date().getTime()) {
          unavailableDates.push(new Date(day.date));
        } else if (!day.slots.length) {
          unavailableDates.push(new Date(day.date));
        } else if (day.slots.length <= 4) {
          lessThanFour.push(new Date(day.date));
        } else if (day.slots.length <= 6) {
          lessThanSix.push(new Date(day.date));
        }
      });

      this.setState({
        slots: slots,
        lessThanFour: lessThanFour,
        lessThanSix: lessThanSix,
        unavailableDates: unavailableDates,
        selectedDay: null,
        selectedSlot: null,
        busy: false,
      });
    } catch (error) {
      cogoToast.error(error.message);
    }
  };

  handleDayClick = (day, { selected, disabled }) => {
    if (this.state.busy) {
      cogoToast.warn("Please wait until we finish loading availability slots!");
      return;
    }
    if (disabled) {
      cogoToast.warn("Sorry, no slots are available on the selected date.");
      return;
    }
    if (selected) {
      this.setState({ selectedDay: null, selectedSlot: null });
    } else {
      this.setState({ selectedDay: day, selectedSlot: null });
    }
  };

  modifiersStyles = {
    lessThanFour: {
      color: "white",
      backgroundColor: "#f67878",
    },
    lessThanSix: {
      color: "white",
      backgroundColor: "#eeb052",
    },
  };

  renderSlots = () => {
    if (!this.state.selectedDay) {
      return <Box>Please choose a date to view slots.</Box>;
    }

    var data = this.state.selectedDay;

    var whichDay = _.findIndex(this.state.slots, function(d) {
      return d.date.getDate() === data.getDate();
    });

    return _.map(this.state.slots[whichDay], (each) => {
      return _.map(each, (e) => {
        return (
          <Box>
            <Button
              text={e}
              colour="blue"
              onClick={() => this.setState({ selectedSlot: e })}
            />
          </Box>
        );
      });
    });
  };

  initiateBooking = () => {
    if (!this.state.selectedDay) {
      cogoToast.error("Please choose a day first.");
      return;
    }
    if (!this.state.selectedSlot) {
      cogoToast.error("Please choose a slot first.");
      return;
    }
    this.props.onBooking(
      this.props._id,
      this.state.selectedDay,
      this.state.selectedSlot
    );
  };

  render() {
    return (
      <>
        <div
          className="doc-profile"
          onClick={() => {
            if (!this.state.expanded) {
              this.setState({ expanded: true });
            }
          }}
        >
          <Flex
            style={{ height: "100%", zIndex: "100" }}
            flexDirection="row"
            p={2}
            flexWrap="wrap"
            justifyContent="center"
          >
            <Box pr={1}>
              <img
                src={this.props.image}
                className="img-doc"
                alt={this.props.name}
              />
            </Box>
            <Box>
              <Flex flexDirection="column">
                <Box pl={2} maxWidth="300px">
                  <div className="doc-name">{this.props.name}</div>
                  <div className="doc-bio">{this.props.bio}</div>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex flexDirection="column" flexWrap="wrap">
                <Box p={2} maxWidth="150px">
                  {_.map(this.props.departments, (d) => {
                    return (
                      <>
                        <Tag className="violet">{d}</Tag>&nbsp;
                      </>
                    );
                  })}
                </Box>
                <Box p={1} maxWidth="150px">
                  <Tag className="grey" multiline>
                    Next Available on{" "}
                    {this.props.nextAvailable ? (
                      <>
                        {new Date(this.props.nextAvailable).toLocaleString(
                          "en-IN",
                          {
                            month: "short",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </>
                    ) : (
                      "Loading..."
                    )}
                  </Tag>
                </Box>
                <Box p={1} maxWidth="150px">
                  <Tag className="grey">
                    Fees: INR{" "}
                    {this.props.fees ? this.props.fees / 100 : "Loading..."}
                  </Tag>
                </Box>
                <Box p={1} maxWidth="150px">
                  <Tag
                    className={this.state.expanded ? "red" : "green"}
                    onClick={() =>
                      this.setState({ expanded: !this.state.expanded })
                    }
                  >
                    {this.state.expanded ? "Cancel" : "Choose Time"}
                  </Tag>
                </Box>
              </Flex>
            </Box>
          </Flex>
          {this.state.expanded ? (
            <>
              <Flex
                flexDirection="column"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
              >
                {/*  <Box>
                  <Flex
                    justifyContent="center"
                    maxHeight="150px"
                    maxWidth="600px"
                    flexWrap="wrap"
                    overflowY="auto"
                  >
                    <Box>
                      <StickyButton
                        text="Virtual Visit: Online"
                        colour="blue"
                        onClick={() => console.log("Time")}
                      />
                    </Box>
                    <Box>
                      <StickyButton
                        text="In Clinic Visit: Unavailable"
                        colour="blue"
                        onClick={() => console.log("Time")}
                      />
                    </Box>
                    <Box>
                      <StickyButton
                        text="Barrackpore"
                        colour="blue"
                        onClick={() => console.log("Time")}
                      />
                    </Box>
                  </Flex>
                </Box> */}

                <Box>
                  <Flex
                    flexWrap="wrap"
                    justifyContent="center"
                    maxWidth="600px"
                    flexDirection="row"
                  >
                    <Box>
                      <DayPicker
                        onDayClick={this.handleDayClick}
                        selectedDays={this.state.selectedDay}
                        modifiers={{
                          lessThanFour: this.state.lessThanFour,
                          lessThanSix: this.state.lessThanSix,
                        }}
                        modifiersStyles={this.modifiersStyles}
                        fromMonth={new Date()}
                        todayButton="Today"
                        disabledDays={this.state.unavailableDates}
                        onMonthChange={(d) => {
                          this.setState(
                            {
                              selectedDay: null,
                              selectedSlot: null,
                              busy: true,
                            },
                            () =>
                              this.getSlotsForMonth(
                                d.getMonth(),
                                d.getFullYear()
                              ).then((v) => v)
                          );
                        }}
                      />
                    </Box>
                    <Box maxWidth="50%" overflowY="auto">
                      <Flex
                        flexWrap="wrap"
                        justifyContent="center"
                        maxHeight="220px"
                        maxWidth="100%"
                        overflowY="auto"
                      >
                        {this.renderSlots()}
                      </Flex>
                      <Flex justifyContent="center">
                        <Box>
                          {this.state.selectedSlot ? (
                            <Button
                              text="Pay and Confirm"
                              colour="green"
                              onClick={() => this.initiateBooking()}
                            />
                          ) : null}
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </>
          ) : null}

          <div className="doc-circle"></div>
        </div>
      </>
    );
  }
}

export default DocProfile;
