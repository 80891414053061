import React from "react";
import Hotkeys from "react-hot-keys";

import Button from "./Button";
import InputSingle from "./InputSingle";
import cogoToast from "cogo-toast";

class TextSingleQ extends React.Component {
  state = {
    enteredText: null,
  };

  renderQuestion = () => {
    return <div className="history-question">{this.props.question}</div>;
  };
  onKeyDown = (keyName, e, whereFrom) => {
    e.preventDefault();
    if (keyName === "enter" && whereFrom === "enteredText") {
      console.log(this.state.enteredText);
      this.onNext();
    }
  };
  renderTextInput = () => {
    return (
      <Hotkeys
        keyName="enter"
        onKeyDown={(keyName, e) => this.onKeyDown(keyName, e, "enteredText")}
        filter={(event) => true}
      >
        <InputSingle
          placeholder={this.props.placeholder}
          onBlur={(e) => {
            this.setState({ enteredText: e });
          }}
        />
      </Hotkeys>
    );
  };

  onNext = () => {
    if (!this.state.enteredText) {
      if (this.props.skip) {
        this.props.onNext("");
        return cogoToast.warn(
          "You skipped the last field. If this was by mistake, you can go back and change."
        );
      }
      return cogoToast.error(
        "It looks like you're trying to submit an empty answer!"
      );
    }
    this.props.onNext(this.state.enteredText);
  };
  renderBackNext = () => {
    return (
      <>
        <Button
          colour="red"
          text="Go Back"
          onClick={() => this.props.onBack()}
        />
        <Button
          colour={this.state.enteredText ? "green" : "grey"}
          text="Next"
          onClick={() => this.onNext()}
        />
      </>
    );
  };
  render() {
    return (
      <div>
        {this.renderQuestion()}
        {this.renderTextInput()}
        <br />
        {this.renderBackNext()}
      </div>
    );
  }
}

export default TextSingleQ;
