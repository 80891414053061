import React from "react";
import { Flex, Box } from "reflexbox";
import { DatePicker } from "@blueprintjs/datetime";
import cogoToast from "cogo-toast";

import Button from "./Button";

class CalendarQ extends React.Component {
  state = {
    selectedDate: null,
  };

  renderQuestion = () => {
    return <div className="history-question">{this.props.question}</div>;
  };

  renderCalendar = () => {
    return (
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Box>
          <DatePicker
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            onChange={(d) => this.setState({ selectedDate: d })}
          />
        </Box>
      </Flex>
    );
  };

  onNext = () => {
    if (!this.state.selectedDate) {
      return cogoToast.error("Please choose a date!");
    }
    this.props.onNext(this.state.selectedDate);
  };
  renderBackNext = () => {
    return (
      <>
        <Button
          colour="red"
          text="Go Back"
          onClick={() => this.props.onBack()}
        />
        <Button
          colour={this.state.selectedDate ? "green" : "grey"}
          text="Next"
          onClick={() => this.onNext()}
        />
      </>
    );
  };
  render() {
    return (
      <div>
        {this.renderQuestion()}
        {this.renderCalendar()}
        <br />
        {this.renderBackNext()}
      </div>
    );
  }
}

export default CalendarQ;
