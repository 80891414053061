// a dumb and quick fix
// there are schema differences in appointments
// created via patient ui v1 and v2
// this will render appointment.v1 inside
// patient.uiv2

// ideally, all documents should have been
// migrated, but I honestly can't think of a way
// to do that cleanly given constraints, so here
// we are.

import React, { useCallback } from "react";
import { Card, H5, H6, Button, Tag, Callout, Pre } from "@blueprintjs/core";
import { Flex, Box } from "reflexbox";
import _ from "lodash";
import axauth from "../utils/authcall";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import DailyIframe from "@daily-co/daily-js";

// import {
//   showFailureToast,
//   showWaitingToast,
//   showSuccessToast,
// } from "../utils/Toaster";
import DetectRTC from "detectrtc";
import { Redirect } from "react-router-dom";

import { Toaster, Position } from "@blueprintjs/core";
const AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.TOP,
  timeout: 2500,
});

const showSuccessToast = (response) => {
  AppToaster.show({
    message: response,
    intent: "success",
    icon: "endorsed",
  });
};

const showFailureToast = (error) => {
  AppToaster.show({
    message: error,
    intent: "danger",
    icon: "error",
  });
};

const showWaitingToast = (content) => {
  AppToaster.show({
    message: content,
    intent: "warning",
    icon: "time",
  });
};

function DropZone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    props.onFiles(acceptedFiles);
  }, []);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {Math.floor((file.size / (1024 * 1024)) * 100) / 100} MB
    </li>
  ));

  return (
    <section className="container">
      <Callout intent="success" icon="cloud-upload">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>You can add more files by clicking here or dragging them here.</p>
        </div>
      </Callout>

      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}
class AppointmentCardCompatV1 extends React.Component {
  state = {
    canUpload: false,
    video: false,
  };
  files = [];
  rtcDetails = null;
  videoEvents = [
    "joining-meeting",
    "joined-meeting",
    "left-meeting",
    "error",
    "camera-error",
    "started-camera",
    "participant-joined",
    "participant-updated",
    "participant-left",
    "network-connection",
    "network-quality-change",
    "app-message",
    "fullscreen",
    "exited-fullscreen",
  ];
  canStart = true;
  checkBrowserCompatibility = async () => {
    DetectRTC.load(function() {
      console.log(DetectRTC);
    });
    this.rtcDetails = DetectRTC;
    console.log(DetectRTC.isWebsiteHasWebcamPermissions);
    if (DetectRTC.osName === "Android") {
      if (DetectRTC.browser.name !== "Chrome") {
        showFailureToast(
          "We only support the browser Chrome on Android. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName === "iOS") {
      if (DetectRTC.browser.name !== "Safari") {
        showFailureToast(
          "We only support the browser Safari on iOS. Please login again from Safari. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName.includes("Windows")) {
      if (DetectRTC.browser.name !== "Chrome") {
        showFailureToast(
          "We only support the browser Chrome on Windows. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName === "Mac OS X") {
      if (
        DetectRTC.browser.name !== "Safari" &&
        DetectRTC.browser.name !== "Chrome"
      ) {
        showFailureToast(
          "We only support the browsers Safari and Chrome on Mac OS X. Please login again from Safari or Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (
      !DetectRTC.isWebsiteHasMicrophonePermissions ||
      !DetectRTC.isWebsiteHasWebcamPermissions
    ) {
      const havePermission = await this.getPermission();
      console.log("HP", havePermission);
      if (havePermission) {
        return true;
      } else {
        showFailureToast(
          "You have either denied permission to the camera or the microphone. Please check your browser settings and try again."
        );
        return false;
      }
    }

    if (
      DetectRTC.hasMicrophone &&
      DetectRTC.hasSpeakers &&
      DetectRTC.hasWebcam
    ) {
      return true;
    } else {
      showFailureToast(
        "We could not detect your microphone, speaker or webcam."
      );
    }
  };
  getPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  getToken = async (id) => {
    const havePermission = await this.checkBrowserCompatibility();
    if (!havePermission) {
      try {
        await axauth("/v1/user/appointmentAnalyticsEx", {
          label: "NO_PERMISSION",
          appointmentId: id,
          ts: new Date(),
          data: this.rtcDetails,
        });
      } catch (err) {
        console.log(err);
      }
      return null;
    }
    try {
      const res = await axauth("/v1/user/appointmentGetMeetingTokenDCO", {
        appointmentId: id,
      });
      console.log(res.data);
      showSuccessToast("Connecting you to the meeting now.");
      try {
        await axauth("/v1/user/appointmentAnalyticsEx", {
          label: "RTC",
          appointmentId: id,
          ts: new Date(),
          data: this.rtcDetails,
        });
        this.setState({
          token: res.data.token,
          url: res.data.url,
          video: true,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      showFailureToast("Failed To Fetch Appointment Token");
      console.log(error);
    }
  };

  genPSUrl = async (a, d) => {
    const res = await axauth("/v1/user/genPSURL", {
      appointmentId: a._id,
      keyname: d.keyName,
    });
    window.open(res.data, "_blank");
    console.log(res.data);
  };
  renderDownloadDocs = (d, arg) => {
    return (
      <Button
        text={d.filename}
        outlined
        onClick={() => this.genPSUrl(arg, d)}
      />
    );
  };
  renderDownloadDocDocs = (d, arg) => {
    return (
      <Button
        text={d.filename}
        outlined
        onClick={() => this.genPSUrl(arg, d)}
      />
    );
  };
  renderUploadedFiles = (arg) => {
    if (!arg.patientDocs.length) {
      return "No Files";
    }
    return _.map(arg.patientDocs, (d) => this.renderDownloadDocs(d, arg));
  };
  renderUploadedDocFiles = (arg) => {
    if (!arg.doctorDocs.length) {
      return "No Files";
    }
    return _.map(arg.doctorDocs, (d) => this.renderDownloadDocDocs(d, arg));
  };
  renderRescheduledTag = (e) => {
    const time = new Date(e.time).toLocaleString([], {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    return (
      <div>
        <Tag intent="primary" minimal multiline>
          {_.startCase(e.type)} by {e.by}. Originally booked with {e.doctor} at{" "}
          {time}.
        </Tag>
      </div>
    );
  };
  renderAlreadyRescheduled = () => {
    /** Expects Array of the form:
     * [{doctor: "Dr Name", time: Time, type: "type", by: "User Name"}]
     * types: referred, rescheduled
     */

    if (!this.props.arg.reschedulingHistory) {
      return null;
    }

    return _.map(this.props.arg.reschedulingHistory, (e) => (
      <Box py={1}>{this.renderRescheduledTag(e)}</Box>
    ));
  };
  renderStartAppointment = () => {
    const currentTime = new Date();
    const startConsultTime = new Date(this.props.arg.time);

    const startConsultBool = (startConsultTime - currentTime) / (1000 * 60);
    const isDone =
      this.props.arg.draftsV2.length &&
      this.props.arg.draftsV2[this.props.arg.draftsV2.length - 1].status ===
        "FINAL"
        ? true
        : false;
    const consultTimeInRange =
      startConsultBool < 5 && startConsultBool > -30 ? true : false;
    const consultTimeInDeepPast = startConsultBool < -30 ? true : false;
    const consultTimeInDeepFuture = startConsultBool > 5 ? true : false;
    const consultButtonActive = !isDone && consultTimeInRange;
    const notDoneYetInPast = !isDone && consultTimeInDeepPast;
    const notDoneAndInFuture = !isDone && consultTimeInDeepFuture;

    if (consultButtonActive) {
      return (
        <div>
          <p>
            Your consultation is scheduled. You will be able to start the
            consultation by clicking the button below 5 minutes before your
            scheduled time. This is a video consultation, so please ensure that
            you are using Chrome if you are on Android, Windows or Mac OS, and
            Safari if you are on iOS. You might be asked to give permission to
            use your microphone and your camera. Denying these permissions will
            not allow you to start the appointment.
          </p>

          <Button
            text="Start Consultation Now"
            intent="success"
            onClick={() => this.getToken(this.props.arg._id)}
          />
        </div>
      );
    }
    if (notDoneYetInPast) {
      return (
        <Tag large multiline intent="danger" minimal>
          The time range for starting this consultation is over. If you think
          this is in error, please contact support.
        </Tag>
      );
    }
    if (notDoneAndInFuture) {
      return (
        <div>
          <p>
            Your consultation is scheduled. You will be able to start the
            consultation by clicking the button below 5 minutes before your
            scheduled time. This is a video consultation, so please ensure that
            you are using Chrome if you are on Android, Windows or Mac OS, and
            Safari if you are on iOS. You might be asked to give permission to
            use your microphone and your camera. Denying these permissions will
            not allow you to start the appointment.
          </p>

          <Button text="Start Consultation Now" disabled />
        </div>
      );
    }
    if (isDone) {
      return (
        <Tag large multiline intent="success" minimal>
          Consultation Completed
        </Tag>
      );
    }
  };
  uploadFiles = async () => {
    const formData = new FormData();
    if (this.files) {
      showWaitingToast(
        "Please wait, we are uploading your files. Please do not refresh this page or press the back button. This may take some time depending on your internet speed."
      );
      var i = 0;
      if (this.props.arg.patientDocs.length) {
        i = this.props.arg.patientDocs.length;
      }
      for (var j = 0; j < this.files.length; j++) {
        var index = i + j;
        // eslint-disable-next-line no-new-wrappers
        formData.append("p" + new String(index), this.files[j]);
      }
      console.log(this.files[0]);
      const newLocal = this.props.arg;
      formData.append("appointmentId", newLocal._id.toString());
      const currentUrl = window.location.href;
      var tenant = "";
      if (currentUrl.includes("localhost")) {
        tenant = "dev";
      } else if (currentUrl.includes("newclouds")) {
        tenant = "disha";
      } else if (currentUrl.includes("shroffeyecentre")) {
        tenant = "shroff";
      } else {
        tenant = _.split(currentUrl, ".")[1];
      }
      try {
        const res = await axios.post(
          "https://apiv2.newclouds.care/v1/user/uploadFileForAppointment",
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("dtoken")}`,
              "X-Radical-Tenant": `Tenant ${tenant}`,
            },
          }
        );
        showSuccessToast("Files successfully uploaded.");
      } catch (err) {
        console.log(err);
      }
    }
  };
  renderUploadFiles = () => {
    /** If Already Done, don't allow. Otherwise, allow. */
    const isDone = this.props.arg.adviceV2 ? true : false;
    if (isDone) {
      return null;
    } else {
      return (
        <>
          <DropZone
            onFiles={(e) => {
              this.files = e;
              if (!this.state.canUpload) {
                this.setState({
                  canUpload: true,
                });
              }
            }}
          />

          <Button
            text="Upload Files"
            onClick={this.uploadFiles}
            disabled={!this.state.canUpload}
          />
        </>
      );
    }
  };
  renderAdviceSection = () => {
    if (
      this.props.arg.draftsV2.length &&
      this.props.arg.draftsV2[this.props.arg.draftsV2.length - 1].status ===
        "FINAL"
    ) {
      return (
        <Button
          outlined
          intent="success"
          text="Download Prescription"
          onClick={() =>
            this.genPSUrl(this.props.arg, { keyName: "ePrescription.pdf" })
          }
        />
      );
    }
    if (this.props.arg.adviceV2) {
      /**
       * This is a new appointment, and consultation is done.
       */
      return (
        <Button
          outlined
          intent="success"
          text="Download Prescription"
          onClick={() =>
            this.genPSUrl(this.props.arg, { keyName: "ePrescription.pdf" })
          }
        />
      );
    }
    if (this.props.arg.advice[0]) {
      return (
        <>
          <Box>
            {" "}
            {this.props.arg.advice[0]
              ? this.props.arg.advice[0]
              : "Advice has not been entered by consultant."}
          </Box>

          <Box width={["100%", "75%", "75%"]} p={1}>
            {this.props.arg.advice[1] === "true"
              ? "A follow up is recommended."
              : "You have not recommended a follow-up yet."}
          </Box>
          <Box width={["100%", "75%", "75%"]} p={1}>
            {" "}
            {this.props.arg.advice[2] === "true"
              ? "The patient should visit the branch as soon as possible."
              : "You have not recommended a branch visit yet. "}
          </Box>
        </>
      );
      /**
       * This is an old appointment, and consultation is done.
       */
    }

    return <div>Prescription has not been updated yet.</div>;
  };
  callAnalytics = async (label, data) => {
    console.log("Logging Data", label, data);
    try {
      await axauth("/v1/user/appointmentAnalyticsEx", {
        label: _.toUpper(_.snakeCase(label)),
        appointmentId: this.props.arg._id,
        ts: new Date(),
        data: data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  renderVideo = () => {
    var isMobileDevice = this.rtcDetails
      ? this.rtcDetails.isMobileDevice
      : null;
    var height = "50vh";
    var width = "50vw";
    var right = "1em";
    var bottom = "1em";
    if (isMobileDevice) {
      height = "100vh";
      width = "100vw";
      right = "0em";
      bottom = "0em";
    }
    if (this.state.video && this.canStart) {
      let callFrame = DailyIframe.createFrame({
        showLeaveButton: "true",
        showFullscreenButton: "true",
        url: this.state.url,
        token: this.state.token,
        iframeStyle: {
          position: "fixed",
          border: "1px solid black",
          width: width,
          height: height,
          right: right,
          bottom: bottom,
        },
      });
      this.canStart = false;
      callFrame.join().then((e) => console.log(e));
      this.videoEvents.forEach((event) => {
        callFrame.on(event, (e) => {
          if (event === "left-meeting") {
            this.setState({
              video: false,
            });
            this.canStart = true;
            this.videoEvents.forEach((event) => {
              callFrame.off(event, (e) => console.log(e));
            });
            callFrame.leave();
            callFrame.destroy();
          }
          if (event === "error") {
            showFailureToast(e.errorMsg);
          }
          this.callAnalytics(event, e);
        });
      });
    }
  };
  render() {
    if (!this.props.arg) {
      return null;
    }
    if (this.state.redirect) {
      return <Redirect to="/consult" />;
    }
    const currentTime = new Date();
    const dob = new Date(this.props.arg.patientInfo.dob);
    const age = (currentTime - dob) / (1000 * 60 * 60 * 24 * 365);
    const ageY = Math.trunc(age);
    const ageM = Math.round((age - ageY) * 12);
    return (
      <div>
        <Card elevation={2} style={{ margin: "10px" }}>
          <Flex flexWrap="wrap" flexDirection="column" alignContent="center">
            {this.renderVideo()}
            <Box width={["100%", "75%", "50%"]} p={1}>
              {this.renderAlreadyRescheduled()}
            </Box>
            <Box width={["100%", "75%", "50%"]} p={1}>
              <H5>Your consultation with {this.props.arg.doctorInfo.name}</H5>
            </Box>
            <Box width={["100%", "75%", "50%"]} p={1}>
              {this.renderStartAppointment()}
            </Box>
            <Box width={["100%", "75%", "50%"]} p={1}>
              <H6>
                Booked Time:{" "}
                {new Date(this.props.arg.time).toLocaleString([], {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </H6>
              <p>
                <b>Age</b>: {ageY} years {ageM} months <b>Sex</b>:{" "}
                {this.props.arg.patientInfo.sex}
              </p>
            </Box>
            <Box width={["100%", "75%", "50%"]} p={1}>
              <b>Complaints: </b>
              {this.props.arg.complaints[0]}
            </Box>
            <Box width={["100%", "75%", "50%"]} p={1}>
              <b>Files You Uploaded: </b>
              {this.renderUploadedFiles(this.props.arg)}
              {this.renderUploadFiles()}
            </Box>
            <Box width={["100%", "75%", "50%"]} p={1}>
              <b> Doctor's Advice: </b>
              {this.renderAdviceSection()}
            </Box>

            <Box width={["100%", "75%", "50%"]} p={1}>
              {" "}
              If you need help or support, please quote this ID:{" "}
              {this.props.arg._id}
            </Box>
          </Flex>
        </Card>
      </div>
    );
  }
}

export default AppointmentCardCompatV1;
