import React from "react";
import { Flex, Box } from "reflexbox";
import { Card, Tag, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router-dom";

import Button from "../micro/Button";
import axauth from "../utils/authcall";

class Profile extends React.Component {
  state = {
    waiting: false,
    redirect: null,
    profileIds: [],
    profiles: [],
  };
  componentDidMount() {
    this.getProfiles();
  }
  getProfiles = async () => {
    this.setState({ waiting: true });
    try {
      var res = await axauth("/v1/user/listProfileIds", {});

      this.setState(
        {
          profileIds: res.data,
          waiting: false,
        },
        () => this.fetchProfileDetails()
      );
    } catch (error) {
      return cogoToast.error(error.message);
    }
  };
  fetchProfileDetails = async () => {
    _.map(this.state.profileIds, async (e) => {
      try {
        var res = await axauth("/v1/user/fetchProfileDetails", { _id: e._id });
        var tempProfiles = this.state.profiles;
        tempProfiles.push(res.data);
        this.setState({
          profiles: tempProfiles,
        });
      } catch (error) {
        return cogoToast.error(error.message);
      }
    });
  };
  renderProfileCard = () => {
    if (this.state.profiles.length === 0) {
      return (
        <div className="login-text">You have not added any profiles yet.</div>
      );
    }
    return _.map(this.state.profiles, (e) => {
      var mailSubject = encodeURIComponent(
        "Need Help With Patient ID " + e._id
      );
      var mailBody = encodeURIComponent(
        "(Please do not change the subject line. Enter the help you need below this line.)"
      );
      var mailRecipient = encodeURIComponent("human@radicalhealth.care");
      var mailURI =
        "mailto:" +
        mailRecipient +
        "?subject=" +
        mailSubject +
        "&body=" +
        mailBody;
      var mailToLink = mailURI;
      return (
        <Box p={1}>
          <Card elevation={2} interactive>
            <Tag minimal intent="primary">
              Name
            </Tag>{" "}
            &nbsp;
            {e.name}
            <br />
            <br />
            <Tag minimal intent="warning">
              Sex
            </Tag>
            &nbsp;
            {e.sex}
            <br />
            <br />
            <Tag minimal intent="warning">
              DoB
            </Tag>
            &nbsp;
            {new Date(e.dob).toDateString()}
            <br />
            <br />
            <Tag minimal intent="danger">
              Address
            </Tag>
            &nbsp;
            {e.address} <br />
            <br />
            <Tag minimal intent="danger">
              Email
            </Tag>
            &nbsp;
            {e.email} <br />
            <br />
            <Tag minimal intent="success">
              Patient ID
            </Tag>
            &nbsp;
            {e.patientPID} <br />
            <br />
            <Button
              onClick={() => {
                window.location.href = mailToLink;
              }}
              text="Need Help?"
              colour="orange"
            />
            <br />
          </Card>
        </Box>
      );
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={this.state.waiting ? { height: "100vh" } : {}}
      >
        {this.state.waiting ? (
          <Spinner size="150" />
        ) : (
          <>
            <Box p={1}>
              <Button
                text="Add New Profile"
                colour="green"
                onClick={() => this.setState({ redirect: "/profile/new" })}
              />
            </Box>
            <Box p={1}>{this.renderProfileCard()}</Box>
          </>
        )}
      </Flex>
    );
  }
}

export default Profile;
