import React from "react";
import {
  Button,
  Drawer,
  Navbar,
  NavbarGroup,
  NavbarHeading,
} from "@blueprintjs/core";
import Home from "../pages/Home";
import cogoToast from "cogo-toast";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

class NavBar extends React.Component {
  state = {
    menuOpen: false,
  };
  onLogOut = () => {
    this.props.appointmentInfoStore.token = null;
    cogoToast.info("Logged Out");
    this.setState({ menuOpen: false });
    this.props.history.push("/");
  };
  render() {
    return (
      <Navbar className="bp3-dark">
        <NavbarGroup align="left">
          <NavbarHeading>
            {this.props.appointmentInfoStore.tenantName
              ? this.props.appointmentInfoStore.tenantName
              : "NewCharts"}
          </NavbarHeading>
        </NavbarGroup>
        <NavbarGroup align="right">
          {this.props.appointmentInfoStore.token ? (
            <Button
              minimal
              text="Menu"
              onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
            />
          ) : null}

          <Drawer
            isOpen={this.state.menuOpen}
            onClose={() => this.setState({ menuOpen: false })}
            title="Menu"
          >
            <div
              style={{ overflowY: "auto" }}
              onClick={() => this.setState({ menuOpen: false })}
            >
              <Home />
            </div>
            <Button onClick={() => this.onLogOut()} minimal text="Log Out" />
          </Drawer>
        </NavbarGroup>
      </Navbar>
    );
  }
}

export default inject("appointmentInfoStore")(observer(withRouter(NavBar)));
