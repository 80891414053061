import { observable, decorate, computed } from "mobx";
import { persist } from "mobx-persist";

export class AppointmentInfoStore {
  apiLink = "https://apiv2.newclouds.care";
  tenant = null;
  profileId = null;
  tenantName = "NewCharts";
  token = null;
  whichPatient = null;
  purpose = null;
  symptomatic = null;
  symptoms = [];
  hpi = {};
  appointmentId = null;

  get showSymptomList() {
    if (this.symptomatic === "No, I don't have any symptoms") {
      return "/appointments/new/hoc";
    } else if (
      this.symptomatic === "Yes, I am experiencing some problems" &&
      !this.symptoms.length
    ) {
      return "/appointments/new/list";
    } else if (
      this.symptomatic === "Yes, I am experiencing some problems" &&
      this.symptoms.length
    ) {
      return "/appointments/new/hoc";
    } else {
      return null;
    }
  }
}

decorate(AppointmentInfoStore, {
  apiLink: [persist, observable],
  tenant: [persist, observable],
  token: [persist, observable],
  whichPatient: [persist, observable],
  purpose: [persist, observable],
  symptomatic: [persist, observable],
  symptoms: [persist("list"), observable],
  showSymptomList: computed,
  hpi: [persist("object"), observable],
  appointmentId: [persist, observable],
  tenantName: [persist, observable],
  profileId: [persist, observable],
});

export default new AppointmentInfoStore();
