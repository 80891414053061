import React from "react";
import DoneAppointment from "./OldAppointment";
import AppointmentCard from "./AppointmentCard";
import OldAppointment from "./AppointmentCardCompatV1";
// need to toggle and run checks here.

class AppointmentHOC extends React.Component {
  select(obj) {
    if (obj.nVer !== 2) {
      return <OldAppointment arg={this.props.appointment} />;
    }
    if (obj.status === "done") {
      return <DoneAppointment appointment={this.props.appointment} />;
    }
    return <AppointmentCard appointment={this.props.appointment} />;
  }

  render() {
    return <div>{this.select(this.props.appointment)}</div>;
  }
}

export default AppointmentHOC;
