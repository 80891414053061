import React from "react";
import _ from "lodash";
import { Flex, Box } from "reflexbox";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { Spinner } from "@blueprintjs/core";

import axauth from "../utils/authcall";
import TextMultiQ from "../micro/TextMultiQ";
import Symptoms from "./Symptoms";

class SymptomHOC extends React.Component {
  state = {
    symptoms: [],
    hpi: [],
    current: 0,
    waiting: false,
    redirect: null,
  };
  mapPatientToMedical = [
    {
      patient: "Here for a routine check",
      medical: "Routine check up",
    },
    {
      patient: "Here for glasses",
      medical: "Refractive Error",
    },
    {
      patient: "Here after a surgery",
      medical: "Post-Op Check Up",
    },
    {
      patient: "Here for a follow up",
      medical: "Follow Up",
    },
    {
      patient: "Eyes feel strained",
      medical: "Eye Strain",
    },
    {
      patient: "Lazy eye",
      medical: "Amblyopia",
    },
    {
      patient: "Eyes are watering",
      medical: "Watering of eye",
    },
    {
      patient: "Eyes feel dry",
      medical: "Dry eye",
    },
    {
      patient: "There's something coming out of my eye",
      medical: "Discharge",
    },
    {
      patient: "I couldn't find my symptom in the list",
      medical: "Other",
    },
    {
      patient: "Eyes are twitching",
      medical: "Spasm",
    },
    {
      patient: "Eyes blinking frequently",
      medical: "Frequent Blinking",
    },
    {
      patient: "Eyes not blinking frequently",
      medical: "Infrequent Blinking",
    },
    {
      patient: "My eyes got hit by something",
      medical: "Mechanical Injury",
    },
    {
      patient: "Chemicals got into my eyes",
      medical: "Chemical Injury",
    },
    {
      patient: "I have problems with my glasses",
      medical: "Glass Problem",
    },
    {
      patient: "I have problems with my contact lens",
      medical: "Contact Lens Problem",
    },
    {
      patient: "My eye power is changing frequently",
      medical: "Fluctuating Refraction",
    },
    {
      patient: "There's something in my eye",
      medical: "Foreign Body Sensation",
    },
    {
      patient: "I have pain in my eye",
      medical: "Pain in the eye",
    },
    {
      patient: "I have pain around my eye",
      medical: "Periorbital pain",
    },
    {
      patient: "I have pain behind my eye",
      medical: "Retrobulbar pain",
    },
    {
      patient: "Eyes are itching",
      medical: "Itching sensation",
    },
    {
      patient: "I can't look at light",
      medical: "Photophobia",
    },
    {
      patient: "Eyes feel burning",
      medical: "Burning sensation",
    },
    {
      patient: "Eyes feel sticky",
      medical: "Sticky eye",
    },
    {
      patient: "Brown spot on eye",
      medical: "Brown spot on th eye",
    },
    {
      patient: "White or cloudy spot on eye",
      medical: "White spot on eye",
    },
    {
      patient: "Red spot on eye",
      medical: "Red spot on eye",
    },
    {
      patient: "Yellow spot on eye",
      medical: "Yellow spot on eye",
    },
    {
      patient: "Black spot on eye",
      medical: "Black spot on eye",
    },
    {
      patient: "Eyes are swollen",
      medical: "Eyes are swollen",
    },
    {
      patient: "There's swelling around the eye",
      medical: "Periorbital swelling",
    },
    {
      patient: "Eye looks red or bloodshot",
      medical: "Redness",
    },
    {
      patient: "Eye looks yellow",
      medical: "Icteric Sclera",
    },
    {
      patient: "Eye is bulging",
      medical: "Proptosis",
    },
    {
      patient: "There's something growing on the eye",
      medical: "Growth",
    },
    {
      patient: "There's blood in the eye",
      medical: "Scleral Haemorrhage",
    },
    {
      patient: "Eyelids look red",
      medical: "Lid Erythema",
    },
    {
      patient: "Eyelids feel flaky or crusty",
      medical: "Crusty eyelids",
    },
    {
      patient: "Dandruff on eyelashes",
      medical: "Seborrhea",
    },
    {
      patient: "Eyelids are drooping or falling",
      medical: "Ptosis",
    },
    {
      patient: "Eyelashes are falling",
      medical: "Madarosis",
    },
    {
      patient: "Unable to close eyes",
      medical: "Lagophthalmos",
    },
    {
      patient: "Lids are turning outward",
      medical: "Ectropion",
    },
    {
      patient: "Lids are turning inward",
      medical: "Entropion",
    },
    {
      patient: "Lid looks deformed",
      medical: "Lid Deformation",
    },
    {
      patient: "There's something growing on the eyelid",
      medical: "Lid Mass",
    },
    {
      patient: "Coloured Spot on Eyelid",
      medical: "Lid Mass",
    },
    {
      patient: "There are black flies or moving spots",
      medical: "Floaters",
    },
    {
      patient: "There are areas I can't see",
      medical: "Scotoma",
    },
    {
      patient: "There's a dark curtain",
      medical: "Curtain in field of view",
    },
    {
      patient: "Seeing double",
      medical: "Diplopia",
    },
    {
      patient: "Lines and things look distorted or curved",
      medical: "Metamorphosia",
    },
    {
      patient: "There are flashes of light",
      medical: "Flashes",
    },
    {
      patient: "Can't see in the dark",
      medical: "Night Blindness",
    },
    {
      patient: "There are glares or halos",
      medical: "Glare",
    },
    {
      patient: "Tunnel vision, can see only in the middle",
      medical: "Peripheral Vision Loss",
    },
    {
      patient: "Can't see in the middle",
      medical: "Central Vision Loss",
    },
    {
      patient: "Cloudy or Blurred Vision Everywhere",
      medical: "Diminution of Vision",
    },
    {
      patient: "Can't see far",
      medical: "Myopia",
    },
    {
      patient: "Can't see near",
      medical: "Hyperopia",
    },
    {
      patient: "Can't see depth",
      medical: "Loss of depth perception",
    },
    {
      patient: "Can't see colours properly",
      medical: "Colour Blindness",
    },
    {
      patient: "Seeing multiple things",
      medical: "Polyopia",
    },
  ];
  componentDidMount() {
    this.setState({ symptoms: this.props.appointmentInfoStore.symptoms });
  }
  onSymptomDone = async (data) => {
    if (this.state.current === this.state.symptoms.length) {
      var hpi = [];
      for (let i = 0; i < this.state.symptoms.length; i++) {
        var mapObj = _.find(this.mapPatientToMedical, (each) => {
          return this.state.symptoms[i] === each.patient;
        });
        var medical = mapObj.medical;
        var symptomDescription = {
          patientDescriptionSymptom: this.state.symptoms[i],
          medical: medical,
          site: this.state.hpi[i][0],
          duration: this.state.hpi[i][1],
          onset: this.state.hpi[i][2],
          progression: this.state.hpi[i][3],
          diurnalVariation: this.state.hpi[i][4],
          relievingFactors: this.state.hpi[i][5],
          aggravatingFactors: this.state.hpi[i][6],
          previousEpisode: this.state.hpi[i][7],
        };

        hpi.push(symptomDescription);
      }
      hpi.push({
        additionalInformation: data,
      });
      try {
        cogoToast.loading("Storing your information");
        this.setState({ waiting: true });
        var res = await axauth("/v1/user/startBookAppointment", {
          hpi: hpi,
          profileId: this.props.appointmentInfoStore.whichPatient,
        });
        this.props.appointmentInfoStore.appointmentId = res.data;

        cogoToast.success(
          "Thanks! Your data is recorded. Now, you can book your appointment."
        );
        this.setState({ redirect: "/appointments/new/book" });
      } catch (error) {
        cogoToast.error(error.message);
        this.setState({ waiting: false, current: 0 });
      }
      this.setState({ redirect: "/appointments/new/book" });
    } else {
      this.setState({
        hpi: [...this.state.hpi, data],
        current: this.state.current + 1,
      });
      return cogoToast.success("Thanks!");
    }
  };
  renderSymptomQuestions = () => {
    var symptom = this.state.symptoms[this.state.current];
    if (this.state.current !== this.state.symptoms.length) {
      return (
        <Symptoms
          symptom={symptom}
          symptomDone={(data) => this.onSymptomDone(data)}
          key={symptom}
        />
      );
    } else {
      return (
        <TextMultiQ
          question="Do you want to add any other information for the doctor to review?"
          placeholder="Add any information you want here. After booking, you will be able to upload files and photos."
          skip={true}
          onBack={() => cogoToast.error("Oops! You can't go back here.")}
          onNext={(text) => this.onSymptomDone(text)}
          key={this.state.current}
        />
      );
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
        flexDirection="column"
      >
        <Box>
          {" "}
          {this.state.waiting ? (
            <Spinner size="150" />
          ) : (
            <Box>{this.renderSymptomQuestions()}</Box>
          )}
        </Box>
      </Flex>
    );
  }
}

export default inject("appointmentInfoStore")(observer(SymptomHOC));
