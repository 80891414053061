import React from "react";
import { Flex, Box } from "reflexbox";
import cogoToast from "cogo-toast";
import Hotkeys from "react-hot-keys";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import { Redirect } from "react-router-dom";

import InputSingle from "../micro/InputSingle";
import Button from "../micro/Button";

class Login extends React.Component {
  state = {
    phone: null,
    waiting: false,
    sent: false,
    otp: null,
    logoUrl: null,
    redirect: null,
  };

  componentDidMount() {
    this.getTenant();
  }
  getTenant = async () => {
    const currentUrl = window.location.href;

    var tenant = "";
    if (currentUrl.includes("localhost")) {
      tenant = "dev";
    } else if (currentUrl.includes("newclouds")) {
      tenant = "disha";
    } else if (currentUrl.includes("shroffeyecentre")) {
      tenant = "shroff";
    } else {
      tenant = _.split(currentUrl, ".")[1];
    }

    var apiLink = this.props.appointmentInfoStore.apiLink;

    try {
      var res = await axios.post(
        apiLink + "/v1/tenant/info",
        { tenant: null },
        {
          headers: {
            "X-Radical-Tenant": `Tenant ${tenant}`,
          },
        }
      );
      this.props.appointmentInfoStore.tenant = tenant;
      this.props.appointmentInfoStore.tenantName = res.data["name"];
      this.setState({
        logoUrl: res.data["logo"],
      });
    } catch (error) {
      return cogoToast.error(error.message);
    }
  };
  phoneInput = (e) => {
    this.setState({
      phone: e,
    });
  };
  otpInput = (e) => {
    this.setState({
      otp: e,
    });
  };

  sendOTP = async () => {
    if (this.state.waiting) {
      return cogoToast.loading("Please wait 30 seconds to re-send OTP again.");
    }
    if (!this.state.phone) {
      return cogoToast.error("Please enter a valid phone number.");
    }
    if (this.state.phone.length !== 10) {
      return cogoToast.error("Please enter a valid phone number.");
    }
    try {
      var res = await axios.post(
        this.props.appointmentInfoStore.apiLink + "/v1/user/sendOtp",
        {
          phoneNumber: "+91" + this.state.phone,
        },
        {
          headers: {
            "X-Radical-Tenant": `Tenant ${this.props.appointmentInfoStore.tenant}`,
          },
        }
      );

      if (res.data["phoneValid"] && res.data["otpSent"]) {
        this.setState({ waiting: true, sent: true });
        setTimeout(() => {
          this.setState({ waiting: false });
        }, 20000);
        cogoToast.success("OTP sent to your phone.");
      }
    } catch (error) {
      console.log(error);
      return cogoToast.error(error.message);
    }
  };
  submitOTP = async () => {
    if (!this.state.otp) {
      return cogoToast.error("Please enter the correct OTP");
    }
    if (this.state.otp.length !== 6) {
      return cogoToast.error("Please enter the correct OTP");
    }

    try {
      var res = await axios.post(
        this.props.appointmentInfoStore.apiLink + "/v1/user/signIn",
        {
          phoneNumber: "+91" + this.state.phone,
          otp: this.state.otp,
        },
        {
          headers: {
            "X-Radical-Tenant": `Tenant ${this.props.appointmentInfoStore.tenant}`,
          },
        }
      );
      this.props.appointmentInfoStore.token = res.data["dtoken"];
      this.props.appointmentInfoStore.profileId = res.data["profileId"];
      localStorage.setItem("dtoken", res.data["dtoken"]);
      Sentry.configureScope(function(scope) {
        scope.setUser({
          id: res.data["_id"],
          username: res.data["phoneNumber"],
        });
      });
      cogoToast.success("Logging In");
      this.setState({
        redirect: "/home",
      });
    } catch (error) {
      return cogoToast.error(error.message);
    }
  };
  onKeyDown = (keyName, e, whereFrom) => {
    e.preventDefault();
    if (keyName === "enter" && whereFrom === "enterMobile") {
      this.sendOTP();
    }
    if (keyName === "enter" && whereFrom === "enterOTP") {
      this.submitOTP();
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.props.appointmentInfoStore.token) {
      return <Redirect to="/home" />;
    }
    return (
      <Flex
        style={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box>
          {this.state.logoUrl ? (
            <img
              src={this.state.logoUrl}
              className="login-logo"
              alt="Hospital Logo"
            />
          ) : null}
          <div className="login-title">We're here for you.</div>
          <div className="login-text">
            See your doctor virtually, from your home. Get their advice,
            prescriptions and medicines.
          </div>
          <div className="login-text">
            Don't let the virus hamper your health!
          </div>
          <div className="login-text">
            Enter your 10-digit mobile number to login securely.
          </div>
          <div>
            <Hotkeys
              keyName="enter"
              onKeyDown={(keyName, e) =>
                this.onKeyDown(keyName, e, "enterMobile")
              }
              filter={(event) => true}
            >
              <InputSingle
                placeholder="Enter Mobile Number"
                onBlur={(e) => this.phoneInput(e)}
                tabIndex="0"
              />
            </Hotkeys>
            {this.state.sent ? (
              <Hotkeys
                keyName="enter"
                onKeyDown={(keyName, e) =>
                  this.onKeyDown(keyName, e, "enterOTP")
                }
                filter={(event) => true}
              >
                <InputSingle
                  placeholder="Enter OTP"
                  onBlur={(e) => this.otpInput(e)}
                  tabIndex="0"
                />
              </Hotkeys>
            ) : null}
          </div>
          <Button
            text="Send OTP"
            colour={this.state.waiting ? "grey" : "green"}
            onClick={() => this.sendOTP()}
            tabIndex="0"
          />
          {this.state.sent ? (
            <Button
              text="Submit OTP and Login"
              colour="violet"
              onClick={() => this.submitOTP()}
              tabIndex="0"
            />
          ) : null}

          <div>
            By using this portal, you agree with the&nbsp;
            <a
              href="https://www.radicalhealth.care/files/PrivacyPolicy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.radicalhealth.care/files/Terms.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            .
          </div>
        </Box>
        <Box>
          <img
            src="/illustrations/login.png"
            className="login-image"
            alt="Backdrop Photograph"
          />
        </Box>
      </Flex>
    );
  }
}

export default inject("appointmentInfoStore")(observer(Login));
