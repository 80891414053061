import React from "react";

import _ from "lodash";
import StickyButton from "./StickyButton";
import { Collapse } from "@blueprintjs/core";
import Button from "./Button";
import { Flex, Box } from "reflexbox";

class ExpandButton extends React.Component {
  state = {
    isOpen: false,
    selectedOption: [],
  };
  onOptionSelect = (e) => {
    var currentArray = this.state.selectedOption;

    if (currentArray.length === 0) {
      currentArray[0] = e;
    } else {
      var idx = currentArray.indexOf(e);
      if (idx > -1) {
        currentArray.splice(idx, 1);
      } else {
        currentArray.push(e);
      }
    }
    this.setState(
      {
        selectedOption: currentArray,
      },
      () => this.props.selectedOptions(this.state.selectedOption)
    );
  };
  renderOptions = () => {
    return (
      <Flex flexDirection="row" flexWrap="wrap" p={2} justifyContent="center">
        {_.map(this.props.options, (e) => {
          return (
            <Box>
              {this.props.multi ? (
                <StickyButton
                  text={e.patient}
                  onClick={() => {
                    this.onOptionSelect(e.medical);
                  }}
                />
              ) : (
                <Button
                  text={e}
                  colour="blue"
                  onClick={() => this.props.onNext(e)}
                />
              )}
            </Box>
          );
        })}
      </Flex>
    );
  };
  render() {
    return (
      <div>
        <StickyButton
          text={this.props.headliner}
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        />
        <Collapse isOpen={this.state.isOpen}>{this.renderOptions()}</Collapse>
      </div>
    );
  }
}

export default ExpandButton;
