import React from "react";
import { Flex, Box } from "reflexbox";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";

import DocProfile from "../micro/DocProfile";
import StickyButton from "../micro/StickyButton";
import InputSingle from "../micro/InputSingle";
import cogoToast from "cogo-toast";
import axauth from "../utils/authcall";
import { inject, observer } from "mobx-react";

class AppointmentType extends React.Component {
  state = {
    paymentLink: null,
    departmentFilter: [],
    searchString: null,
    doctorList: [],
    showList: [],
    listOfDepartments: [],
  };

  componentDidMount() {
    this.getDoctorList();
  }

  getDoctorList = async () => {
    this.setState({ waiting: true });
    try {
      var res = await axauth("/v1/user/getDoctorIds", {});
      var departments = [];
      _.map(res.data, (doc) => {
        departments.push(doc.departments);
      });
      departments = _.uniq(_.flatten(departments));
      this.setState({ listOfDepartments: departments });
      this.setState({ doctorList: res.data, showList: res.data });
      this.setState({ waiting: false });
    } catch (error) {
      cogoToast.error(error.message);
    }
  };
  search = () => {
    var list = this.state.doctorList;
    var departmentFilter = this.state.departmentFilter;
    if (this.state.departmentFilter.length) {
      list = _.filter(this.state.doctorList, function(d) {
        if (_.intersection(d.departments, departmentFilter).length) {
          return d;
        }
      });
    } else {
      list = this.state.doctorList;
    }
    var string = this.state.searchString;
    if (this.state.searchString) {
      list = _.filter(this.state.doctorList, function(d) {
        if (_.lowerCase(d.name).includes(_.lowerCase(string))) {
          return d;
        }
      });
    }
    this.setState({ showList: list });
  };

  setDepartmentFilter = (dept) => {
    var currentArray = this.state.departmentFilter;

    if (currentArray.length === 0) {
      currentArray[0] = dept;
    } else {
      var idx = currentArray.indexOf(dept);
      if (idx > -1) {
        currentArray.splice(idx, 1);
      } else {
        currentArray.push(dept);
      }
    }

    this.setState(
      {
        departmentFilter: currentArray,
      },
      () => this.search()
    );
  };
  renderDepartmentOptions = () => {
    return _.map(this.state.listOfDepartments, (d) => {
      return (
        <StickyButton
          text={d}
          onClick={(data) => this.setDepartmentFilter(data)}
        />
      );
    });
  };
  initiateBooking = async (doctorId, day, slot) => {
    if (this.state.paymentLink) {
      cogoToast.success("Attempting Payment");
      window.location = this.state.paymentLink;
      return;
    }
    if (!this.props.appointmentInfoStore.appointmentId) {
      cogoToast.error(
        "We ran into an error. Please re-start booking this appointment."
      );
    }
    cogoToast.loading("Initiating Booking");
    try {
      await axauth("/v1/user/selectDrForAppointment", {
        doctorId: doctorId,
        apptId: this.props.appointmentInfoStore.appointmentId,
      });

      cogoToast.loading("Reserving Time From Doctor's Schedule");
      try {
        var res = await axauth("/v1/user/reserveSlotForAppointment", {
          apptId: this.props.appointmentInfoStore.appointmentId,
          year: day.getFullYear(),
          month: day.getMonth(),
          day: day.getDate(),
          slot: slot,
        });
        cogoToast.success("Reserved Successfully. Redirecting For Payment.");
        this.setState({ paymentLink: res.data.paymentLink });

        window.location = res.data.paymentLink;
      } catch (error) {
        cogoToast.error(error.message);
      }
    } catch (error) {
      cogoToast.error(error.message);
    }
  };
  renderDoctorProfiles = () => {
    return _.map(this.state.showList, (doc) => {
      return (
        <DocProfile
          _id={doc._id}
          key={doc._id}
          name={doc.name}
          departments={doc.departments}
          onBooking={this.initiateBooking}
          bio={doc.bio}
          fees={doc.costPerConsultation}
          nextAvailable={doc.nextAvailable}
          image={doc.image}
        />
      );
    });
  };
  render() {
    if (this.state.waiting) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          style={{ height: "100vh" }}
        >
          <Box>
            <Spinner size="150" />
          </Box>
        </Flex>
      );
    }
    return (
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Box>
          <div className="login-title">Book Appointment</div>
          <div className="login-text">
            Please choose the doctor you want to see.
          </div>
        </Box>
        <Box>{this.renderDepartmentOptions()}</Box>
        <Box>
          <InputSingle
            placeholder="Search for a specific doctor"
            onBlur={(text) =>
              this.setState({ searchString: text }, () => this.search())
            }
          />
        </Box>
        <Box>{this.renderDoctorProfiles()}</Box>
      </Flex>
    );
  }
}

export default inject("appointmentInfoStore")(observer(AppointmentType));
