import React from "react";
import Button from "./Button";

class StickyButton extends React.Component {
  state = {
    isSticky: false,
  };
  render() {
    return (
      <>
        <Button
          colour={this.state.isSticky ? "yellow" : "grey"}
          text={this.props.text}
          onClick={() => {
            this.setState({ isSticky: !this.state.isSticky });
            this.props.onClick(this.props.text);
          }}
        />
      </>
    );
  }
}

export default StickyButton;
