import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/browser";
import { Provider } from "mobx-react";
import RootStore from "./store";

Sentry.init({
  dsn:
    "https://b608e99a367149cf8cd4bcfdbf19a583@o389248.ingest.sentry.io/5227191",
});

ReactDOM.render(
  <React.StrictMode>
    <Provider appointmentInfoStore={RootStore.appointmentInfoStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
