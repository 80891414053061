import React from "react";
import { Flex, Box } from "reflexbox";
import { Spinner } from "@blueprintjs/core";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { Redirect } from "react-router-dom";

import axauth from "../utils/authcall";
import TextSingleQ from "../micro/TextSingleQ";
import OptionQ from "../micro/OptionQ";
import CalendarQ from "../micro/CalendarQ";
import TextMultiQ from "../micro/TextMultiQ";

class NewProfile extends React.Component {
  state = {
    submitted: false,
    questions: [
      {
        question: "What's your full name?",
        type: "single",
        placeholder: "Jane Doe",
        capitalise: true,
        skip: false,
      },
      {
        question: "How do you identify yourself?",
        type: "option",
        capitalise: false,
        options: ["Male", "Female", "Trans Male", "Trans Female", "Other"],
        skip: false,
      },
      {
        question: "What's your date of birth?",
        type: "calendar",
        capitalise: false,
        skip: false,
      },
      {
        question: "What's your email address?",
        type: "single",
        capitalise: false,
        placeholder: "janedoe@gmail.com",
        skip: true,
      },
      {
        question: "What's your current address?",
        type: "multi",
        capitalise: false,
        placeholder: "1600, Africa Avenue, New Delhi - 110001",
        skip: false,
      },
      {
        question:
          "Do you know your Patient ID? If you don't, you can skip this question.",
        type: "single",
        capitalise: false,
        placeholder: "PID01010101",
        skip: true,
      },
      {
        question:
          "Have you been diagnosed with one or more of the following diseases? You can choose as many as you want, or choose none.",
        type: "multi-option",
        capitalise: false,
        options: [
          "Diabetes",
          "Hypertension",
          "Diabetic Retinopathy",
          "Glaucoma",
          "Age Related Macular Degeneration",
          "None Of These",
        ],
        skip: false,
      },
      { question: "Thanks! Creating New Profile.", type: "terminal" },
    ],
    current: 0, //Goes from 0 to 6.
    answers: [],
    redirect: null,
  };
  submitted = false;
  submitNewProfileData = async () => {
    if (!this.submitted) {
      this.submitted = true;
    } else {
      return null;
    }
    var newProfile = {
      name: this.state.answers[0],
      sex: this.state.answers[1],
      dob: this.state.answers[2],
      email: this.state.answers[3],
      address: this.state.answers[4],
      pid: this.state.answers[5],
      diseases: this.state.answers[6],
    };

    this.setState({ waiting: true });
    try {
      await axauth("/v1/user/createNewProfile", newProfile);
      this.setState({ waiting: false, redirect: "/appointments" });
      return cogoToast.success("New Profile Created Successfully!");
    } catch (error) {
      cogoToast.error(error.message);
      this.setState({ waiting: false, current: 0 });
    }
  };
  onBack = () => {
    if (this.state.current !== 0) {
      this.setState({ current: this.state.current - 1 });
    } else {
      cogoToast.warn("You can't go back, this is the first question!");
    }
  };
  onNext = (data) => {
    var currentAnswers = this.state.answers;

    if (this.state.questions[this.state.current].capitalise) {
      currentAnswers[this.state.current] = _.startCase(data);
    } else {
      currentAnswers[this.state.current] = data;
    }

    this.setState({ answers: currentAnswers, current: this.state.current + 1 });
  };
  renderQuestion = () => {
    var question = this.state.questions[this.state.current];
    if (question.type === "single") {
      return (
        <TextSingleQ
          question={question.question}
          placeholder={question.placeholder}
          skip={question.skip}
          onBack={() => this.onBack()}
          onNext={(text) => this.onNext(text)}
          key={this.state.current}
        />
      );
    }
    if (question.type === "option") {
      return (
        <OptionQ
          question={question.question}
          options={question.options}
          onBack={() => this.onBack()}
          onNext={(option) => this.onNext(option)}
          key={this.state.current}
        />
      );
    }

    if (question.type === "calendar") {
      return (
        <CalendarQ
          question={question.question}
          onBack={() => this.onBack()}
          onNext={(date) => this.onNext(date)}
          maxDate={new Date()}
          minDate={new Date(1900, 0, 1)}
          key={this.state.current}
        />
      );
    }

    if (question.type === "multi") {
      return (
        <TextMultiQ
          question={question.question}
          placeholder={question.placeholder}
          onBack={() => this.onBack()}
          onNext={(text) => this.onNext(text)}
          key={this.state.current}
        />
      );
    }

    if (question.type === "multi-option") {
      return (
        <OptionQ
          multi
          question={question.question}
          options={question.options}
          onBack={() => this.onBack()}
          onNext={(option) => this.onNext(option)}
          key={this.state.current}
        />
      );
    }
    if (question.type === "terminal") {
      cogoToast.loading(question.question);
      this.submitNewProfileData();
      return null;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
        flexDirection="column"
      >
        {this.state.waiting ? (
          <Spinner size="150" />
        ) : (
          <Box>{this.renderQuestion()}</Box>
        )}
      </Flex>
    );
  }
}

export default NewProfile;
