import React from "react";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { Flex, Box } from "reflexbox";
import Button from "../micro/Button";
import AppointmentHOC from "../micro/AppointmentHOC";
import { Redirect } from "react-router-dom";

import axauth from "../utils/authcall";

class AppointmentList extends React.Component {
  state = {
    appointmentList: [],
    redirect: null,
  };
  componentDidMount() {
    this.getAppointmentList();
  }
  getAppointmentList = async () => {
    cogoToast.loading("Please wait, loading your appointments...");
    try {
      var res = await axauth("/v1/user/getAllAppointments", {});
      this.setState({ appointmentList: res.data });
    } catch (error) {
      cogoToast.error(error.message);
    }
  };

  renderAppointments = () => {
    return _.map(this.state.appointmentList, (e) => {
      return <AppointmentHOC appointment={e} />;
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box>
          <Button
            text="Book New Appointment"
            colour="green"
            onClick={() => this.setState({ redirect: "/appointments/new" })}
          />
        </Box>
        <Box>
          {this.state.appointmentList.length
            ? this.renderAppointments()
            : "You haven't booked any appointments so far."}
        </Box>
      </Flex>
    );
  }
}

export default AppointmentList;
