import React from "react";
import cogoToast from "cogo-toast";
import Button from "./Button";
import InputMulti from "./InputMulti";

class TextMultiQ extends React.Component {
  state = {
    enteredText: null,
  };

  renderQuestion = () => {
    return <div className="history-question">{this.props.question}</div>;
  };

  renderTextInput = () => {
    return (
      <InputMulti
        placeholder={this.props.placeholder}
        onBlur={(e) => {
          this.setState({ enteredText: e });
        }}
      />
    );
  };
  onNext = () => {
    if (!this.state.enteredText) {
      return cogoToast.error(
        "It looks like you're trying to submit an empty answer!"
      );
    }
    this.props.onNext(this.state.enteredText);
  };
  renderBackNext = () => {
    return (
      <>
        <Button
          colour="red"
          text="Go Back"
          onClick={() => this.props.onBack()}
        />
        <Button
          colour={this.state.enteredText ? "green" : "grey"}
          text="Next"
          onClick={() => this.onNext()}
        />
      </>
    );
  };
  render() {
    return (
      <div>
        {this.renderQuestion()}
        {this.renderTextInput()}
        <br />
        {this.renderBackNext()}
      </div>
    );
  }
}

export default TextMultiQ;
