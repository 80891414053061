import React from "react";
import { Flex, Box } from "reflexbox";
import { Spinner } from "@blueprintjs/core";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import axauth from "../utils/authcall";
import OptionQ from "../micro/OptionQ";

class PreSymptomQuestions extends React.Component {
  state = {
    waiting: false,
    profiles: [{ name: "Someone Else", _id: null }],
    profileNames: ["Someone Else"],
    selectedProfile: null,
    purpose: null,
    symptomatic: null,
    redirect: null,
  };
  componentDidMount() {
    this.props.appointmentInfoStore.whichPatient = null;
    this.props.appointmentInfoStore.purpose = null;
    this.props.appointmentInfoStore.symptomatic = null;
    this.props.appointmentInfoStore.symptoms = [];
    this.props.appointmentInfoStore.hpi = {};
    this.getProfiles();
  }
  getProfiles = async () => {
    try {
      this.setState({ waiting: true });
      var res = await axauth("/v1/user/listProfileIds", {});
      if (!res.data.length) {
        cogoToast.error("You haven't made any profiles yet!");
        this.setState({ redirect: "/profile" });
        return null;
      }

      _.map(res.data, (each) => {
        this.setState({
          profiles: [
            ...this.state.profiles,
            {
              name: each["name"],
              _id: each["_id"],
            },
          ],
          profileNames: [...this.state.profileNames, each["name"]],
        });
      });
      /* 
      for (var i = 0; i < res.data.length; i++) {
        try {
          var profileDetails = await axauth("/v1/user/fetchProfileDetails", {
            _id: res.data[i],
          });

          this.setState({
            profiles: [
              ...this.state.profiles,
              {
                name: profileDetails.data["name"],
                _id: profileDetails.data["_id"],
              },
            ],
            profileNames: [
              ...this.state.profileNames,
              profileDetails.data["name"],
            ],
          });
        } catch (error) {
          cogoToast.error(error.message);
        }
      } */
      this.setState({ waiting: false });
    } catch (error) {
      cogoToast.error(error.message);
      this.setState({ waiting: false });
    }
  };
  selectProfile = (data) => {
    if (data === "Someone Else") {
      cogoToast.info("You need to register their profile first!");
      this.setState({ redirect: "/profile" });
    } else {
      var profile = _.find(this.state.profiles, function(o) {
        return o.name === data;
      });
      this.setState({ selectedProfile: profile._id });

      this.props.appointmentInfoStore.whichPatient = profile._id;
    }
  };
  renderWhoAreYouBookingFor = () => {
    return (
      <OptionQ
        question="Who are you booking this appointment for?"
        options={this.state.profileNames}
        onBack={() =>
          cogoToast.error(
            "Sorry! You can't go back, this is the first question!"
          )
        }
        onNext={(data) => this.selectProfile(data)}
      />
    );
  };
  setPurpose = (data) => {
    this.setState({
      purpose: data,
    });
    this.props.appointmentInfoStore.purpose = data;
  };
  renderWhyAreYouHereToday = () => {
    var options = [
      "I have a follow-up scheduled",
      "I am here for a regular check-up",
      "I have had cataract surgery",
      "I am under treatment for glaucoma",
      "I am under treatment for a corneal disease",
      "I am under treatment for a retinal disease",
      "Other Reasons",
    ];
    return (
      <OptionQ
        question="What brings you here today?"
        options={options}
        onBack={() =>
          cogoToast.error(
            "Sorry! You can't go back. Please start over if you want to book for someone else."
          )
        }
        onNext={(data) => this.setPurpose(data)}
      />
    );
  };
  setSymptomatic = (data) => {
    this.setState({
      symptomatic: data,
    });
    this.props.appointmentInfoStore.symptomatic = data;
    cogoToast.success("Thanks! Please tell us more.");
    this.setState({
      redirect: this.props.appointmentInfoStore.showSymptomList,
    });
  };
  renderAreYouSymptomatic = () => {
    var options = [
      "Yes, I am experiencing some problems",
      "No, I don't have any symptoms",
    ];
    return (
      <OptionQ
        question="Are you experiencing any problems or are you coming in for a normal check-up?"
        options={options}
        onBack={() => this.setState({ purpose: null })}
        onNext={(data) => this.setSymptomatic(data)}
      />
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
        flexDirection="column"
      >
        {this.state.waiting ? (
          <Spinner size="150" />
        ) : (
          <Box>
            {this.state.selectedProfile
              ? this.state.purpose
                ? this.renderAreYouSymptomatic()
                : this.renderWhyAreYouHereToday()
              : this.renderWhoAreYouBookingFor()}
          </Box>
        )}
      </Flex>
    );
  }
}

export default inject("appointmentInfoStore")(observer(PreSymptomQuestions));
