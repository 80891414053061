import React from "react";
import { Card, Button } from "@blueprintjs/core";
import { Flex } from "reflexbox";
import Viewer, { SpecialZoomLevel } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import Feedback from "./Feedback";

class OldAppointment extends React.Component {
  state = {
    showPrescription: false,
    showImages: true,
    showFeedback: false,
    hasFeedBack: false,
  };

  renderPDF = () => {
    return (
      <div style={{ height: "750px" }}>
        <Viewer
          fileUrl={this.props.appointment.prescriptionPdf}
          defaultScale={SpecialZoomLevel.PageFit}
        />
      </div>
    );
  };
  renderFeedback = () => {
    return (
      <Feedback
        appointmentId={this.props.appointment._id}
        feedbackDone={() => this.setState({ hasFeedBack: true })}
      />
    );
  };

  render() {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Card elevation={2} interactive>
          Your appointment with {this.props.appointment.doctorName} on{" "}
          {new Date(this.props.appointment.time).toLocaleString("en-IN", {
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            year: "2-digit",
          })}{" "}
          &nbsp;
          {this.props.appointment.hasPrescription ? (
            <>
              <Button
                text={
                  this.state.showPrescription
                    ? "Hide Details"
                    : "Show Prescription"
                }
                minimal
                intent={this.state.showPrescription ? "warning" : "success"}
                icon={this.state.showPrescription ? "lock" : "endorsed"}
                onClick={() =>
                  this.setState({
                    showPrescription: !this.state.showPrescription,
                  })
                }
              />{" "}
              {this.props.appointment.hasFeedBack || this.state.hasFeedBack ? (
                <Button
                  text="Your Feedback Is Recorded."
                  minimal
                  intent="primary"
                  icon="endorsed"
                />
              ) : (
                <Button
                  text="Give Feedback"
                  minimal
                  intent="primary"
                  icon="layout"
                  onClick={() =>
                    this.setState({ showFeedback: !this.state.showFeedback })
                  }
                />
              )}
            </>
          ) : (
            <Button
              text="The doctor is writing your prescription now."
              minimal
              intent="warning"
              icon="time"
            />
          )}
          {this.state.showPrescription ? this.renderPDF() : null}
          {this.state.showFeedback ? this.renderFeedback() : null}
        </Card>
      </Flex>
    );
  }
}

export default OldAppointment;
