import React from "react";
import _ from "lodash";
import { Flex, Box } from "reflexbox";
import cogoToast from "cogo-toast";
import Button from "./Button";
import StickyButton from "./StickyButton";

class OptionQ extends React.Component {
  state = {
    selectedOption: [],
  };

  onOptionSelect = (e) => {
    var currentArray = this.state.selectedOption;

    if (currentArray.length === 0) {
      currentArray[0] = e;
    } else {
      var idx = currentArray.indexOf(e);
      if (idx > -1) {
        currentArray.splice(idx, 1);
      } else {
        currentArray.push(e);
      }
    }
    this.setState({
      selectedOption: currentArray,
    });
  };
  renderQuestion = () => {
    return <div className="history-question">{this.props.question}</div>;
  };

  renderOptions = () => {
    return (
      <Flex flexDirection="row" flexWrap="wrap" p={2} justifyContent="center">
        {_.map(this.props.options, (e) => {
          return (
            <Box>
              {this.props.multi ? (
                <StickyButton
                  key={e}
                  text={e}
                  onClick={() => {
                    this.onOptionSelect(e);
                  }}
                />
              ) : (
                <Button
                  text={e}
                  colour="blue"
                  onClick={() => this.props.onNext(e)}
                />
              )}
            </Box>
          );
        })}
      </Flex>
    );
  };
  onNext = () => {
    if (!this.state.selectedOption.length) {
      return cogoToast.error("Please choose at least one option!");
    }
    this.props.onNext(this.state.selectedOption);
  };
  renderBackNext = () => {
    return (
      <>
        <Button
          colour="red"
          text="Go Back"
          onClick={() => this.props.onBack()}
        />
        {this.props.multi ? (
          <Button
            colour={this.state.selectedOption.length ? "green" : "grey"}
            text="Next"
            onClick={() => this.onNext()}
          />
        ) : null}
      </>
    );
  };
  render() {
    return (
      <div>
        {this.renderQuestion()}
        {this.renderOptions()}
        <br />
        {this.renderBackNext()}
      </div>
    );
  }
}

export default OptionQ;
