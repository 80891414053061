import { FilePond } from "react-filepond";

import "filepond/dist/filepond.css";
import { inject, observer } from "mobx-react";
import Button from "./Button";
import { Flex, Box } from "reflexbox";

import React from "react";

class Upload extends React.Component {
  state = {
    files: [],
  };
  handleInit() {}

  render() {
    var apiUrl = this.props.appointmentInfoStore.apiLink;
    var tenant = this.props.appointmentInfoStore.tenant;
    var token = this.props.appointmentInfoStore.token;
    var _id = this.props._id;
    if (this.props.what === "eye") {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Box>
            {this.props.psUrl ? (
              <Button
                text="View Uploaded File"
                onClick={() => {
                  window.open(this.props.psUrl, "_blank");
                }}
                colour="green"
              />
            ) : null}
          </Box>
          <Box>
            <div
              className="upload"
              key={`${this.props.eye}_${this.props.gaze}`}
            >
              <FilePond
                allowMultiple={false}
                dropOnPage={true}
                labelIdle={
                  'Click to take a photo, or <span class="filepond--label-action"> upload </span>'
                }
                name={`${this.props.eye}_${this.props.gaze}`}
                server={{
                  url: apiUrl,
                  process: {
                    url: "/v1/user/uploadEyeImages/" + _id,
                    method: "POST",
                    headers: {
                      "X-Radical-Tenant": `Tenant ${tenant}`,
                      Authorization: `Bearer ${token}`,
                    },
                  },
                  revert: null,
                  restore: null,
                  load: null,
                  fetch: null,
                }}
                key={`${this.props.eye}_${this.props.gaze}`}
                ref={(ref) => (this.pond = ref)}
                oninit={() => this.handleInit()}
                onprocessfiles={() => {
                  this.props.filesUpdated();
                }}
                onupdatefiles={(fileItems) => {
                  // Set current file objects to this.state
                  this.setState({
                    files: fileItems.map((fileItem) => fileItem.file),
                  });
                }}
              />
            </div>
          </Box>
        </Flex>
      );
    } else if (this.props.what === "files") {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Box>
            <div className="upload" key={`${this.props._id}`}>
              <FilePond
                allowMultiple={true}
                dropOnPage={true}
                labelIdle={
                  'Click to take a photo, or <span class="filepond--label-action"> upload </span>'
                }
                server={{
                  url: apiUrl,
                  process: {
                    url: "/v1/user/uploadPtDocuments/" + _id,
                    method: "POST",
                    headers: {
                      "X-Radical-Tenant": `Tenant ${tenant}`,
                      Authorization: `Bearer ${token}`,
                    },
                  },
                  revert: null,
                  restore: null,
                  load: null,
                  fetch: null,
                }}
                key={`${this.props._id}`}
                ref={(ref) => (this.pond = ref)}
                oninit={() => this.handleInit()}
                onprocessfiles={() => {
                  this.props.filesUpdated();
                }}
                onupdatefiles={(fileItems) => {
                  // Set current file objects to this.state
                  this.setState({
                    files: fileItems.map((fileItem) => fileItem.file),
                  });
                }}
              />
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}

export default inject("appointmentInfoStore")(observer(Upload));
