import React from "react";

class Button extends React.Component {
  render() {
    return (
      <>
        <div
          className={`button ${this.props.colour} ${
            this.props.square ? "square" : this.props.cta ? "cta" : ""
          } `}
          onClick={() => {
            this.props.onClick();
          }}
        >
          {this.props.square ? (
            this.props.image ? (
              <img
                src={`/illustrations/${this.props.image}.png`}
                height="26px"
                style={{ position: "absolute", right: "15px", bottom: "15px" }}
                alt="Button Logo"
              />
            ) : null
          ) : null}

          <div className="button-text">{this.props.text}</div>
          <div className="circle"></div>
        </div>
      </>
    );
  }
}

export default Button;
