import React from "react";
import _ from "lodash";
import ExpandButton from "../micro/ExpandButton";

import { inject, observer } from "mobx-react";
import Button from "../micro/Button";
import { Redirect } from "react-router-dom";
import cogoToast from "cogo-toast";

class SymptomList extends React.Component {
  list = [
    {
      medicalCategory: "vision",
      category: "Problems With Seeing",
      options: [
        {
          patient: "There are black flies or moving spots",
          medical: "Floaters",
        },
        {
          patient: "There are areas I can't see",
          medical: "Scotoma",
        },
        {
          patient: "There's a dark curtain",
          medical: "Curtain in field of view",
        },
        {
          patient: "Seeing double",
          medical: "Diplopia",
        },
        {
          patient: "Lines and things look distorted or curved",
          medical: "Metamorphosia",
        },
        {
          patient: "There are flashes of light",
          medical: "Flashes",
        },
        {
          patient: "Can't see in the dark",
          medical: "Night Blindness",
        },
        {
          patient: "There are glares or halos",
          medical: "Glare",
        },
        {
          patient: "Tunnel vision, can see only in the middle",
          medical: "Peripheral Vision Loss",
        },
        {
          patient: "Can't see in the middle",
          medical: "Central Vision Loss",
        },
        {
          patient: "Cloudy or Blurred Vision Everywhere",
          medical: "Diminution of Vision",
        },
        {
          patient: "Can't see far",
          medical: "Myopia",
        },
        {
          patient: "Can't see near",
          medical: "Hyperopia",
        },
        {
          patient: "Can't see depth",
          medical: "Loss of depth perception",
        },
        {
          patient: "Can't see colours properly",
          medical: "Colour Blindness",
        },
        {
          patient: "Seeing multiple things",
          medical: "Polyopia",
        },
      ],
    },
    {
      medicalCategory: "movements",
      category: "Blinking and Twitching",
      options: [
        {
          patient: "Eyes are twitching",
          medical: "Spasm",
        },
        {
          patient: "Eyes blinking frequently",
          medical: "Frequent Blinking",
        },
        {
          patient: "Eyes not blinking frequently",
          medical: "Infrequent Blinking",
        },
      ],
    },

    {
      medicalCategory: "trauma",
      category: "Injury and Accidents",
      options: [
        {
          patient: "My eyes got hit by something",
          medical: "Mechanical Injury",
        },
        {
          patient: "Chemicals got into my eyes",
          medical: "Chemical Injury",
        },
      ],
    },
    {
      medicalCategory: "refraction",
      category: "Glasses and Lenses",
      options: [
        {
          patient: "I have problems with my glasses",
          medical: "Glass Problem",
        },
        {
          patient: "I have problems with my contact lens",
          medical: "Contact Lens Problem",
        },
        {
          patient: "My eye power is changing frequently",
          medical: "Fluctuating Refraction",
        },
      ],
    },
    {
      medicalCategory: "sensations",
      category: "Eye Feels Different",
      options: [
        {
          patient: "There's something in my eye",
          medical: "Foreign Body Sensation",
        },
        {
          patient: "I have pain in my eye",
          medical: "Pain in the eye",
        },
        {
          patient: "I have pain around my eye",
          medical: "Periorbital pain",
        },
        {
          patient: "I have pain behind my eye",
          medical: "Retrobulbar pain",
        },
        {
          patient: "Eyes are itching",
          medical: "Itching sensation",
        },
        {
          patient: "I can't look at light",
          medical: "Photophobia",
        },
        {
          patient: "Eyes feel burning",
          medical: "Burning sensation",
        },
        {
          patient: "Eyes feel sticky",
          medical: "Sticky eye",
        },
      ],
    },
    {
      medicalCategory: "appearance",
      category: "Eye looks different",
      options: [
        {
          patient: "Brown spot on eye",
          medical: "Brown spot on th eye",
        },
        {
          patient: "White or cloudy spot on eye",
          medical: "White spot on eye",
        },
        {
          patient: "Red spot on eye",
          medical: "Red spot on eye",
        },
        {
          patient: "Yellow spot on eye",
          medical: "Yellow spot on eye",
        },
        {
          patient: "Black spot on eye",
          medical: "Black spot on eye",
        },
        {
          patient: "Eyes are swollen",
          medical: "Eyes are swollen",
        },
        {
          patient: "There's swelling around the eye",
          medical: "Periorbital swelling",
        },
        {
          patient: "Eye looks red or bloodshot",
          medical: "Redness",
        },
        {
          patient: "Eye looks yellow",
          medical: "Icteric Sclera",
        },
        {
          patient: "Eye is bulging",
          medical: "Proptosis",
        },
        {
          patient: "There's something growing on the eye",
          medical: "Growth",
        },
        {
          patient: "There's blood in the eye",
          medical: "Scleral Haemorrhage",
        },
      ],
    },
    {
      medicalCategory: "lid",
      category: "Problem with Eyelids",
      options: [
        {
          patient: "Eyelids look red",
          medical: "Lid Erythema",
        },
        {
          patient: "Eyelids feel flaky or crusty",
          medical: "Crusty eyelids",
        },
        {
          patient: "Dandruff on eyelashes",
          medical: "Seborrhea",
        },
        {
          patient: "Eyelids are drooping or falling",
          medical: "Ptosis",
        },
        {
          patient: "Eyelashes are falling",
          medical: "Madarosis",
        },
        {
          patient: "Unable to close eyes",
          medical: "Lagophthalmos",
        },
        {
          patient: "Lids are turning outward",
          medical: "Ectropion",
        },
        {
          patient: "Lids are turning inward",
          medical: "Entropion",
        },
        {
          patient: "Lid looks deformed",
          medical: "Lid Deformation",
        },
        {
          patient: "There's something growing on the eyelid",
          medical: "Lid Mass",
        },
        {
          patient: "Coloured Spot on Eyelid",
          medical: "Lid Mass",
        },
      ],
    },
  ];
  mapPatientToMedical = [
    {
      patient: "Here for a routine check",
      medical: "Routine check up",
    },
    {
      patient: "Here for glasses",
      medical: "Refractive Error",
    },
    {
      patient: "Here after a surgery",
      medical: "Post-Op Check Up",
    },
    {
      patient: "Here for a follow up",
      medical: "Follow Up",
    },
    {
      patient: "Eyes feel strained",
      medical: "Eye Strain",
    },
    {
      patient: "Lazy eye",
      medical: "Amblyopia",
    },
    {
      patient: "Eyes are watering",
      medical: "Watering of eye",
    },
    {
      patient: "Eyes feel dry",
      medical: "Dry eye",
    },
    {
      patient: "There's something coming out of my eye",
      medical: "Discharge",
    },
    {
      patient: "I couldn't find my symptom in the list",
      medical: "Other",
    },
    {
      patient: "Eyes are twitching",
      medical: "Spasm",
    },
    {
      patient: "Eyes blinking frequently",
      medical: "Frequent Blinking",
    },
    {
      patient: "Eyes not blinking frequently",
      medical: "Infrequent Blinking",
    },
    {
      patient: "My eyes got hit by something",
      medical: "Mechanical Injury",
    },
    {
      patient: "Chemicals got into my eyes",
      medical: "Chemical Injury",
    },
    {
      patient: "I have problems with my glasses",
      medical: "Glass Problem",
    },
    {
      patient: "I have problems with my contact lens",
      medical: "Contact Lens Problem",
    },
    {
      patient: "My eye power is changing frequently",
      medical: "Fluctuating Refraction",
    },
    {
      patient: "There's something in my eye",
      medical: "Foreign Body Sensation",
    },
    {
      patient: "I have pain in my eye",
      medical: "Pain in the eye",
    },
    {
      patient: "I have pain around my eye",
      medical: "Periorbital pain",
    },
    {
      patient: "I have pain behind my eye",
      medical: "Retrobulbar pain",
    },
    {
      patient: "Eyes are itching",
      medical: "Itching sensation",
    },
    {
      patient: "I can't look at light",
      medical: "Photophobia",
    },
    {
      patient: "Eyes feel burning",
      medical: "Burning sensation",
    },
    {
      patient: "Eyes feel sticky",
      medical: "Sticky eye",
    },
    {
      patient: "Brown spot on eye",
      medical: "Brown spot on th eye",
    },
    {
      patient: "White or cloudy spot on eye",
      medical: "White spot on eye",
    },
    {
      patient: "Red spot on eye",
      medical: "Red spot on eye",
    },
    {
      patient: "Yellow spot on eye",
      medical: "Yellow spot on eye",
    },
    {
      patient: "Black spot on eye",
      medical: "Black spot on eye",
    },
    {
      patient: "Eyes are swollen",
      medical: "Eyes are swollen",
    },
    {
      patient: "There's swelling around the eye",
      medical: "Periorbital swelling",
    },
    {
      patient: "Eye looks red or bloodshot",
      medical: "Redness",
    },
    {
      patient: "Eye looks yellow",
      medical: "Icteric Sclera",
    },
    {
      patient: "Eye is bulging",
      medical: "Proptosis",
    },
    {
      patient: "There's something growing on the eye",
      medical: "Growth",
    },
    {
      patient: "There's blood in the eye",
      medical: "Scleral Haemorrhage",
    },
    {
      patient: "Eyelids look red",
      medical: "Lid Erythema",
    },
    {
      patient: "Eyelids feel flaky or crusty",
      medical: "Crusty eyelids",
    },
    {
      patient: "Dandruff on eyelashes",
      medical: "Seborrhea",
    },
    {
      patient: "Eyelids are drooping or falling",
      medical: "Ptosis",
    },
    {
      patient: "Eyelashes are falling",
      medical: "Madarosis",
    },
    {
      patient: "Unable to close eyes",
      medical: "Lagophthalmos",
    },
    {
      patient: "Lids are turning outward",
      medical: "Ectropion",
    },
    {
      patient: "Lids are turning inward",
      medical: "Entropion",
    },
    {
      patient: "Lid looks deformed",
      medical: "Lid Deformation",
    },
    {
      patient: "There's something growing on the eyelid",
      medical: "Lid Mass",
    },
    {
      patient: "Coloured Spot on Eyelid",
      medical: "Lid Mass",
    },
    {
      patient: "There are black flies or moving spots",
      medical: "Floaters",
    },
    {
      patient: "There are areas I can't see",
      medical: "Scotoma",
    },
    {
      patient: "There's a dark curtain",
      medical: "Curtain in field of view",
    },
    {
      patient: "Seeing double",
      medical: "Diplopia",
    },
    {
      patient: "Lines and things look distorted or curved",
      medical: "Metamorphosia",
    },
    {
      patient: "There are flashes of light",
      medical: "Flashes",
    },
    {
      patient: "Can't see in the dark",
      medical: "Night Blindness",
    },
    {
      patient: "There are glares or halos",
      medical: "Glare",
    },
    {
      patient: "Tunnel vision, can see only in the middle",
      medical: "Peripheral Vision Loss",
    },
    {
      patient: "Can't see in the middle",
      medical: "Central Vision Loss",
    },
    {
      patient: "Cloudy or Blurred Vision Everywhere",
      medical: "Diminution of Vision",
    },
    {
      patient: "Can't see far",
      medical: "Myopia",
    },
    {
      patient: "Can't see near",
      medical: "Hyperopia",
    },
    {
      patient: "Can't see depth",
      medical: "Loss of depth perception",
    },
    {
      patient: "Can't see colours properly",
      medical: "Colour Blindness",
    },
    {
      patient: "Seeing multiple things",
      medical: "Polyopia",
    },
  ];
  state = {
    redirect: null,
  };
  selectedSymptoms = {};
  symptomSelect = (category, options) => {
    this.selectedSymptoms[category] = options;
  };
  renderExpanders = () => {
    return _.map(this.list, (e) => {
      return (
        <ExpandButton
          headliner={e.category}
          multi
          options={e.options}
          selectedOptions={(options) =>
            this.symptomSelect(e.medicalCategory, options)
          }
        />
      );
    });
  };
  submitSymptoms = () => {
    var flattenedList = [];
    _.map(this.selectedSymptoms, (s) => flattenedList.push(s));
    flattenedList = _.flatten(flattenedList);
    var patientFriendlyList = [];
    _.map(flattenedList, (each) => {
      var mapObj = _.find(this.mapPatientToMedical, function(m) {
        return m.medical === each;
      });
      patientFriendlyList.push(mapObj.patient);
    });
    if (patientFriendlyList.length) {
      this.props.appointmentInfoStore.symptoms = patientFriendlyList;
      this.setState({
        redirect: this.props.appointmentInfoStore.showSymptomList,
      });
    } else {
      return cogoToast.error(
        "Please select at least one symptom or re-start booking."
      );
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <div className="history-question">
          Please choose all the symptoms that you are experiencing. Click on
          each category to expand and view options.
        </div>
        {this.renderExpanders()}
        <Button
          colour="green"
          text="Submit and Proceed"
          onClick={() => this.submitSymptoms()}
        />
      </div>
    );
  }
}

export default inject("appointmentInfoStore")(observer(SymptomList));
