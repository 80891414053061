import React from "react";
import { TextArea } from "@blueprintjs/core";

class InputMulti extends React.Component {
  render() {
    return (
      <div style={{ padding: "10px" }}>
        <TextArea
          growVertically
          onBlur={(e) => this.props.onBlur(e.target.value)}
          placeholder={
            this.props.placeholder ? this.props.placeholder : "Type Here"
          }
          style={{ height: "100px", fontSize: "large", width: "300px" }}
        />
      </div>
    );
  }
}

export default InputMulti;
