import axios from "axios";
import _ from "lodash";

const authCall = async (loc, data) => {
  const currentUrl = window.location.href;
  var tenant = "";
  if (currentUrl.includes("localhost")) {
    tenant = "dev";
  } else if (currentUrl.includes("newclouds")) {
    tenant = "disha";
  } else if (currentUrl.includes("shroffeyecentre")) {
    tenant = "shroff";
  } else {
    tenant = _.split(currentUrl, ".")[1];
  }
  var store = JSON.parse(localStorage.getItem("appointmentInfo"));

  return await axios.post(store.apiLink + loc, data, {
    headers: {
      Authorization: `Bearer ${store.token}`,
      "X-Radical-Tenant": `Tenant ${tenant}`,
    },
  });
};
export default authCall;
