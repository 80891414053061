import { create } from "mobx-persist";
import appointmentInfoStore from "./appointmentInfoStore";

const hydrate = create({
  storage: localStorage,
});

class RootStore {
  appointmentInfoStore = appointmentInfoStore;

  constructor() {
    Promise.all([
      hydrate("appointmentInfo", this.appointmentInfoStore),
    ]).then(() => console.log("Application Hydrated."));
  }
}

export default new RootStore();
