import React from "react";
import { Flex, Box } from "reflexbox";
import cogoToast from "cogo-toast";

import TextSingleQ from "../micro/TextSingleQ";
import OptionQ from "../micro/OptionQ";
import CalendarQ from "../micro/CalendarQ";
import TextMultiQ from "../micro/TextMultiQ";

class Symptoms extends React.Component {
  state = {
    questions: [
      {
        question: "In which eye are you experiencing this symptom?",
        type: "option",
        options: [
          "Both Eyes, Equally",
          "Both Eyes, But More In The Right",
          "Both Eyes, But More In The Left",
          "Left Eye",
          "Right Eye",
        ],
      },
      {
        question: "Since how long have you been experiencing this symptom?",
        type: "option",
        options: [
          "A Day",
          "1 to 3 Days",
          "3 to 7 Days",
          "1 to 2 Weeks",
          "2 Weeks to 1 Month",
          "1 to 3 Months",
          "3 to 6 Months",
          "6 Months to 1 Year",
          "1 to 2 Years",
          "2 to 5 Years",
          "5 to 10 Years",
          "More Than 10 Years",
          "Since Birth",
        ],
      },
      {
        question: "How did you start feeling this symptom?",
        type: "option",
        options: ["Gradually", "Suddenly"],
      },
      {
        question: "Is the symptom worsening or becoming better?",
        type: "option",
        options: [
          "Improving",
          "Worsening",
          "Remaining Constant",
          "Fluctuating",
        ],
      },
      {
        question: "Is the symptom varying on a daily basis?",
        type: "multi-option",
        options: [
          "Worse in the morning",
          "Worse in the afternoon",
          "Worse in the evening",
          "Worse at night",
          "Randomly",
          "Remains constant",
        ],
      },
      {
        question: "Is there anything that makes it better?",
        type: "multi-option",
        options: [
          "Nothing",
          "Blinking",
          "Opening the eye",
          "Closing the eye",
          "Rubbing the eye",
          "Sleeping",
          "Waking Up",
          "Eating",
          "Turning eye to one side",
          "Pressing the eye",
          "Looking at a light source",
          "Going out in sunlight",
          "Coming into a dark room",
          "Looking at screens",
          "Prolonged fasting",
          "Wearing dark glasses",
          "Not wearing spectacles",
          "Wearing spectacles",
          "Exercise",
          "Near distance work",
          "Moving the eyes",
          "Coughing",
          "Sneezing",
          "Tilting the head",
        ],
      },
      {
        question: "Is there anything that makes it worse?",
        type: "multi-option",
        options: [
          "Nothing",
          "Blinking",
          "Opening the eye",
          "Closing the eye",
          "Rubbing the eye",
          "Sleeping",
          "Waking Up",
          "Eating",
          "Turning eye to one side",
          "Pressing the eye",
          "Looking at a light source",
          "Going out in sunlight",
          "Coming into a dark room",
          "Looking at screens",
          "Prolonged fasting",
          "Wearing dark glasses",
          "Not wearing spectacles",
          "Wearing spectacles",
          "Exercise",
          "Near distance work",
          "Moving the eyes",
          "Coughing",
          "Sneezing",
          "Tilting the head",
        ],
      },
      {
        question: "Have you experienced this in the past?",
        type: "option",
        options: [
          "Never Before",
          "Happens Periodically",
          "Yes, in the last 2 weeks",
          "Yes, in the last 1 month",
          "Yes, in the last 3 months",
          "Yes, in the last 6 months",
          "Yes, in the last 1 year",
          "Yes, in the last 2 years",
          "Yes, more than 2 years ago",
        ],
      },

      { question: "Thanks!", type: "terminal" },
    ],
    current: 0, //Goes from 0 to 6.
    answers: [],
  };

  onBack = () => {
    if (this.state.current !== 0) {
      this.setState({ current: this.state.current - 1 });
    } else {
      cogoToast.warn("You can't go back, this is the first question!");
    }
  };
  onNext = (data) => {
    var currentAnswers = this.state.answers;
    currentAnswers[this.state.current] = data;
    this.setState({ answers: currentAnswers, current: this.state.current + 1 });
  };
  renderQuestion = () => {
    var question = this.state.questions[this.state.current];
    if (question.type === "single") {
      return (
        <TextSingleQ
          question={question.question}
          placeholder={question.placeholder}
          onBack={() => this.onBack()}
          onNext={(text) => this.onNext(text)}
          key={this.state.current}
        />
      );
    }
    if (question.type === "option") {
      return (
        <OptionQ
          question={question.question}
          options={question.options}
          onBack={() => this.onBack()}
          onNext={(option) => this.onNext(option)}
          key={this.state.current}
        />
      );
    }

    if (question.type === "calendar") {
      return (
        <CalendarQ
          question={question.question}
          onBack={() => this.onBack()}
          onNext={(date) => this.onNext(date)}
          maxDate={new Date()}
          key={this.state.current}
        />
      );
    }

    if (question.type === "multi") {
      return (
        <TextMultiQ
          question={question.question}
          placeholder={question.placeholder}
          onBack={() => this.onBack()}
          onNext={(text) => this.onNext(text)}
          key={this.state.current}
        />
      );
    }

    if (question.type === "multi-option") {
      return (
        <OptionQ
          multi
          question={question.question}
          options={question.options}
          onBack={() => this.onBack()}
          onNext={(option) => this.onNext(option)}
          key={this.state.current}
        />
      );
    }
    if (question.type === "terminal") {
      this.props.symptomDone(this.state.answers);
      return null;
    }
  };

  render() {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
        flexDirection="column"
      >
        <Box>
          <div className="history-question">{this.props.symptom}</div>
        </Box>
        <Box>{this.renderQuestion()}</Box>
      </Flex>
    );
  }
}

export default Symptoms;
