import React from "react";
import Button from "./Button";
import { Flex, Box } from "reflexbox";
import DetectRTC from "detectrtc";
import cogoToast from "cogo-toast";

class Help extends React.Component {
  state = {
    iPhone: false,
    android: false,
    macOS: false,
    windows: false,
    havePermission: false,
    testStarted: false,
  };
  rtcDetails = null;

  componentDidMount() {
    this.checkBrowserCompatibility();
  }
  checkBrowserCompatibility = async () => {
    DetectRTC.load(function() {
      console.log(DetectRTC);
    });
    this.rtcDetails = DetectRTC;

    if (DetectRTC.osName === "Android") {
      await cogoToast.info(
        "We have detected that you are on an android device."
      );
      this.setState({ android: true });
      if (DetectRTC.browser.name !== "Chrome") {
        cogoToast.error(
          "We only support the browser Chrome on Android. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      } else {
        cogoToast.success(
          "We have detected that you are using the " +
            DetectRTC.browser.name +
            " browser."
        );
      }
    }

    if (DetectRTC.osName === "iOS") {
      this.setState({ iPhone: true });
      await cogoToast.info("We have detected that you are on an iPhone.");
      if (DetectRTC.browser.name !== "Safari") {
        cogoToast.error(
          "We only support the browser Safari on iOS. Please login again from Safari. If you think this is an error, contact support."
        );
        return false;
      } else {
        cogoToast.success(
          "We have detected that you are using the " +
            DetectRTC.browser.name +
            " browser."
        );
      }
    }

    if (DetectRTC.osName.includes("Windows")) {
      this.setState({ windows: true });
      await cogoToast.info(
        "We have detected that you are on a Windows device."
      );
      if (
        DetectRTC.browser.name !== "Chrome" &&
        DetectRTC.browser.name !== "Firefox"
      ) {
        cogoToast.error(
          "We only support the browser Chrome on Windows. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      } else {
        cogoToast.success(
          "We have detected that you are using the " +
            DetectRTC.browser.name +
            " browser."
        );
      }
    }

    if (DetectRTC.osName === "Mac OS X") {
      this.setState({ macOS: true });

      await cogoToast.info("We have detected that you are on a MacOS device.");
      if (
        DetectRTC.browser.name !== "Safari" &&
        DetectRTC.browser.name !== "Chrome"
      ) {
        cogoToast.error(
          "We only support the browsers Safari and Chrome on Mac OS X. Please login again from Safari or Chrome. If you think this is an error, contact support."
        );
        return false;
      } else {
        cogoToast.success(
          "We have detected that you are using the " +
            DetectRTC.browser.name +
            " browser."
        );
      }
    }
  };
  getPermission = async () => {
    this.setState({ testStarted: true });
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      this.setState({ havePermission: true });
    } catch (err) {
      console.log(err);
      this.setState({ havePermission: false });
    }
  };
  renderTestCamera = () => {
    return (
      <Button
        text={this.state.havePermission ? "Successfully Tested" : "Test Run"}
        colour={this.state.havePermission ? "green" : "blue"}
        onClick={() => this.getPermission()}
      />
    );
  };
  renderHelper = () => {
    if (this.state.macOS) {
      return <>{this.renderMacOsHelp()} </>;
    } else if (this.state.windows) {
      return <>{this.renderWindowsHelp()} </>;
    } else if (this.state.android) {
      return <>{this.renderAndroidHelp()} </>;
    } else if (this.state.iPhone) {
      return <>{this.renderiPhoneHelp()} </>;
    }
  };
  renderTestResult = () => {
    if (this.state.testStarted) {
      return (
        <>
          {this.state.havePermission ? (
            "You are all set up! Your permissions are correctly configured. You can click 'Start Consultation' 5 minutes before your appointment time. "
          ) : (
            <>{this.renderHelper()}</>
          )}
        </>
      );
    } else return null;
  };
  renderTestHelp = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box style={{ textAlign: "center" }}>
          To do this consultation, you need to provide camera and microphone
          permissions. Please click the "Test Run" button below to test these
          permissions.
        </Box>
        <Box style={{ textAlign: "center" }}>
          When you click the button, you may get a pop-up to ask for permission.
          Please click <b>"Allow"</b> if you get a pop-up.
        </Box>
        <Box>{this.renderTestCamera()}</Box>
        <Box style={{ textAlign: "center" }}>{this.renderTestResult()}</Box>
      </Flex>
    );
  };
  renderWindowsHelp = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box style={{ textAlign: "center" }}>
          First, make sure you are using a supported browser such as Google
          Chrome on Windows.
        </Box>
        <Box>
          Now, you can unblock the website from accessing the camera and
          microphone by following the official Google instructions&nbsp;
          <b>
            <a
              href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en&oco=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </b>
        </Box>
        <Box>
          Once you have done that, please click "Test Run" again to check!
        </Box>
      </Flex>
    );
  };
  renderAndroidHelp = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box style={{ textAlign: "center" }}>
          First, make sure you are using a supported browser such as Google
          Chrome on Android.
        </Box>
        <Box>
          Now, you can unblock the website from accessing the camera and
          microphone by following the official Google instructions&nbsp;
          <b>
            <a
              href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid&hl=en&oco=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </b>
        </Box>
        <Box>
          Once you have done that, please click "Test Run" again to check!
        </Box>
      </Flex>
    );
  };
  renderMacOsHelp = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box style={{ textAlign: "center" }}>
          macOS Mojave and later released a security setting that requires you
          to grant the operating system camera and microphone permissions, under
          System Preferences.
        </Box>
        <Box style={{ textAlign: "center" }}>
          1. Go to System Preferences &gt; Security &amp; Privacy &gt; Camera.
        </Box>
        <Box style={{ textAlign: "center" }}>
          2. Click the box next to the browser you're using. It'll quit and
          reopen your browser.
        </Box>
        <Box style={{ textAlign: "center" }}>
          3. Do the same for Systems Preferences &gt; Privacy &gt; Microphone.
        </Box>
        <Box>
          <img
            src="/illustrations/help/mac-system.png"
            alt="Mac OS System Settings Screenshot"
            width="400px"
          />
        </Box>
        <Box>
          4. Now, you can unblock the website from accessing the camera and
          microphone by following the official Apple instructions&nbsp;
          <b>
            <a
              href="https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </b>
        </Box>
        <Box>
          5. Once you have done that, please click "Test Run" again to check!
        </Box>
      </Flex>
    );
  };
  renderiPhoneHelp = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box style={{ textAlign: "center" }}>
          Typically, Safari prompts for camera/microphone access for each call.
          This is a privacy setting imposed by Apple. If this doesn't work, we
          need to check if camera access is enabled for Safari on iOS.
        </Box>
        <Box style={{ textAlign: "center" }}>1. Open the Settings app</Box>
        <Box style={{ textAlign: "center" }}>2. Tap on Safari </Box>
        <Box style={{ textAlign: "center" }}>
          3. Scroll down to Camera &amp; Microphone
        </Box>
        <Box>
          <img
            src="/illustrations/help/ios-settings.jpg"
            alt="iOS System Settings Screenshot"
            width="400px"
          />
        </Box>
        <Box style={{ textAlign: "center" }}>
          4. Confirm that "Ask" or "Allow" is checked for each of them (camera
          and microphone).
        </Box>
        <Box>
          <img
            src="/illustrations/help/ios-camera.jpg"
            alt="iOS Camera Settings Screenshot"
            width="400px"
          />
        </Box>
        <Box>
          4. Now, you can refresh and test again. Please click <b>Allow</b> when
          the prompt comes.
        </Box>
      </Flex>
    );
  };
  render() {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
      >
        <Box>Please select the device you're using.</Box>
        <Box p={2}>
          <Button
            text="Android Phone"
            colour={this.state.android ? "yellow" : "grey"}
            onClick={() =>
              this.setState({
                android: !this.state.android,
                iPhone: false,
                macOS: false,
                windows: false,
              })
            }
          />
        </Box>
        <Box p={2}>{this.state.android ? this.renderTestHelp() : null}</Box>
        <Box p={2}>
          <Button
            text="Windows Laptop"
            colour={this.state.windows ? "yellow" : "grey"}
            onClick={() =>
              this.setState({
                windows: !this.state.windows,
                iPhone: false,
                android: false,
                macOS: false,
              })
            }
          />
        </Box>
        <Box p={2}>{this.state.windows ? this.renderTestHelp() : null}</Box>
        <Box p={2}>
          <Button
            text="MacOS Laptop"
            colour={this.state.macOS ? "yellow" : "grey"}
            onClick={() =>
              this.setState({
                macOS: !this.state.macOS,
                iPhone: false,
                android: false,
                windows: false,
              })
            }
          />
        </Box>
        <Box p={2}>{this.state.macOS ? this.renderTestHelp() : null}</Box>
        <Box p={2}>
          <Button
            text="iPhone"
            colour={this.state.iPhone ? "yellow" : "grey"}
            onClick={() =>
              this.setState({
                iPhone: !this.state.iPhone,
                android: false,
                macOS: false,
                windows: false,
              })
            }
          />
        </Box>
        <Box p={2}>{this.state.iPhone ? this.renderTestHelp() : null}</Box>
      </Flex>
    );
  }
}

export default Help;
