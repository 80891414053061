import React from "react";
import { Dialog, H3 } from "@blueprintjs/core";
import { Flex, Box } from "reflexbox";
import StarRatingComponent from "react-star-rating-component";
import InputMulti from "./InputMulti";
import Button from "./Button";
import axauth from "../utils/authcall";
import cogoToast from "cogo-toast";

class Feedback extends React.Component {
  state = {
    audioRating: null,
    videoRating: null,
    doctorRating: null,
    feedback: null,
    isOpen: false,
  };
  componentDidMount() {
    this.setState({ isOpen: true });
  }
  submitFeedback = async () => {
    cogoToast.loading("Submitting Feedback");
    try {
      var feedbackObj = {
        apptId: this.props.appointmentId,
        doctor: this.state.doctorRating ? this.state.doctorRating : 5,
        audio: this.state.audioRating ? this.state.audioRating : 5,
        video: this.state.videoRating ? this.state.videoRating : 5,
        comment: this.state.feedback ? this.state.feedback : "NA",
      };
      await axauth("/v1/user/addFeedback", feedbackObj);
      cogoToast.success("Thanks for your feedback! We appreciate it.");
      this.props.feedbackDone();
      this.setState({ isOpen: false });
    } catch (error) {
      cogoToast.error(error.message);
    }
  };
  render() {
    return (
      <Dialog
        isOpen={this.state.isOpen}
        canOutsideClickClose
        canEscapeKeyClose
        isCloseButtonShown
        onClose={() => this.setState({ isOpen: false })}
        title="Feedback"
        icon="layout-grid"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Box p={2}>
            <H3>Your feedback helps us improve.</H3>
          </Box>
          <Box py={2}>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={2}
            >
              <Box p={2}>How was your video on this consultation?</Box>
              <Box>
                <StarRatingComponent
                  name="videoRating"
                  starCount={5}
                  value={this.state.videoRating}
                  onStarClick={(nextValue, prevValue, name) => {
                    this.setState({ videoRating: nextValue });
                  }}
                />
              </Box>
            </Flex>
          </Box>
          <Box py={2}>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={2}
            >
              <Box p={2}>How was your audio on this consultation?</Box>
              <Box>
                <StarRatingComponent
                  name="audioRating"
                  starCount={5}
                  value={this.state.audioRating}
                  onStarClick={(nextValue, prevValue, name) => {
                    this.setState({ audioRating: nextValue });
                  }}
                />
              </Box>
            </Flex>
          </Box>
          <Box py={2}>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={2}
            >
              <Box p={2}>How satisfied were you with your doctor?</Box>
              <Box>
                <StarRatingComponent
                  name="doctorRating"
                  starCount={5}
                  value={this.state.doctorRating}
                  onStarClick={(nextValue, prevValue, name) => {
                    this.setState({ doctorRating: nextValue });
                  }}
                />
              </Box>
            </Flex>
          </Box>
          <Box>
            <InputMulti
              placeholder="Please enter any other feedback here."
              onBlur={(data) => this.setState({ feedback: data })}
            />
          </Box>
          <Box>
            <Button
              colour="violet"
              text="Submit"
              onClick={() => this.submitFeedback()}
            />
          </Box>
        </Flex>
      </Dialog>
    );
  }
}

export default Feedback;
