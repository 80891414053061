import React from "react";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router-dom";
import { Flex, Box } from "reflexbox";

import Button from "../micro/Button";
import axauth from "../utils/authcall";

class Home extends React.Component {
  state = {
    name: null,
    profileStatus: null,
    redirect: null,
  };
  ctaLine = () => {
    if (this.state.profileStatus === "shell") {
      return "Start by filling in your profile.";
    }
    if (this.state.profileStatus === "yesAppointment") {
      return "Click the Appointments button to view your appointment and add details.";
    }
    if (this.state.profileStatus === "noAppointment") {
      return "Click the Appointments button to book an appointment.";
    }
  };
  componentDidMount() {
    this.getProfileStatus();
  }
  getProfileStatus = async () => {
    try {
      var res = await axauth("/v1/user/getProfileStatus", {});
      this.setState({
        profileStatus: res.data["status"],
        name: res.data["name"],
      });
    } catch (error) {
      cogoToast.error(error.message);
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
        flexDirection="column"
      >
        <Box>
          <div className="login-title">
            Welcome{this.state.name ? `, ${this.state.name}` : null}.
          </div>
          <div className="login-text">{this.ctaLine()}</div>
        </Box>
        <Box>
          <Button
            image="pill"
            colour="grey"
            square
            text="Medicines"
            onClick={() =>
              cogoToast.loading("We are working to add medicine delivery soon!")
            }
          />
          <Button
            image="eye"
            colour="grey"
            square
            text="Diagnostics"
            onClick={() =>
              cogoToast.loading(
                "We are working to add digital diagnostics soon!"
              )
            }
          />
          <Button
            image="chart"
            colour={this.state.profileStatus === "shell" ? "grey" : "green"}
            square
            text="Appointments"
            onClick={
              this.state.profileStatus === "shell"
                ? () => cogoToast.warn("Please add your profile first!")
                : () => this.setState({ redirect: "/appointments" })
            }
          />
          <Button
            image="profile"
            colour="yellow"
            square
            text="My Profile"
            onClick={() => this.setState({ redirect: "/profile" })}
          />
        </Box>
      </Flex>
    );
  }
}

export default Home;
